import React, { Component } from "react"
import _ from 'lodash';
import moment from 'moment';

import WorkoutContext from '../../../context/workout/WorkoutContext';

class ExerciseNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: !_.isEmpty(props.notes) ? props.notes : [],
            newNote: ''
        }
    }

    async componentDidMount() {
        let response = await this.context.getNotes(this.props.exerciseId);
        if(response.success) {
          await this.setState({notes: response.workout_notes});
        }

        if(this.state.notes.length > 0) {
            var div = document.getElementById('note-list');
            div.scrollTop = div.scrollHeight - div.clientHeight;
        }
    }

    async componentDidUpdate(prevProps) {
        if(!_.isEqual(prevProps.exerciseId, this.props.exerciseId)) {
            let response = await this.context.getNotes(this.props.exerciseId);
            if(response.success) {
              await this.setState({notes: response.workout_notes});
            }

            if(this.state.notes.length > 0) {
                var div = document.getElementById('note-list');
                div.scrollTop = div.scrollHeight - div.clientHeight;
            }
        }
    }

    setNewNote = (e) => {
        this.setState({newNote: e.target.value});
    }

    newNote = async (e) => {
        e.preventDefault();
        let newMessageResponse = await this.context.addNote(this.props.exerciseId, this.state.newNote);
        if(newMessageResponse.success) {
            let response = await this.context.getNotes(this.props.exerciseId);
            if(response.success) {
              await this.setState({notes: response.workout_notes, newNote: ''});
              var div = document.getElementById('note-list');
              div.scrollTop = div.scrollHeight - div.clientHeight;
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                {!this.props.isWorkoutNoteForm ?
                <React.Fragment>
                <h4 className="no-border">Exercise Notes</h4>
                <br />
                <div className="notes-thread" id="note-list">
                    {this.state.notes.map(note => {
                        return (
                            <article key={note._id} className={`message ${note.posted_by === 'user' ? 'message-update' : 'is-primary'}`}>
                                <div className="message-body">
                                    {note.notes} <br /><sub>({moment(note.created_at).format('MM-DD-YY')}) {note.posted_by === 'trainer' ? process.env.REACT_APP_TRAINER_NAME : 'Me'}</sub>
                                </div>
                            </article>
                        );
                    })} 
                </div>
                </React.Fragment> : null}
                <form onSubmit={this.newNote}>
                    <div className="field">
                        <div className="control">
                            <textarea className="textarea" placeholder="Add a note" onChange={this.setNewNote} value={this.state.newNote} required />
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <button type="submit" disabled={_.isEmpty(this.state.newNote)} className={`button is-large ${_.isEmpty(this.state.newNote) ? '' : 'is-primary'}`}>Send</button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );    
    }

}

ExerciseNotes.contextType = WorkoutContext;
export default ExerciseNotes;

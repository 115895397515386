import React, { useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';
import 'stream-chat-react/dist/css/index.css';
import {
    Chat,
    Channel,
    ChannelList,
    ChannelListMessenger,
    MessageList,
    Thread,
    MessageInput,
    Window,
    ChannelPreviewMessenger
} from 'stream-chat-react';

import P3 from '../../../utils/P3'

const chatClient = new StreamChat(process.env.REACT_APP_STREAM_CHAT_KEY); // open stream API connection
const name = process.env.REACT_APP_TRAINER_NAME;
const id = process.env.REACT_APP_TRAINER_ID;

const AdminMessages = () => {

const [isConnected, setIsConnected] = useState('');

  const init = async () => {

    const getTrainerToken = async () => {
      if (process.env.NODE_ENV === 'production') {
        if (process.env.REACT_APP_TRAINER_STREAM_TOKEN) {
          setIsConnected(true)
          return process.env.REACT_APP_TRAINER_STREAM_TOKEN
        }
        else {
          const response = await P3.getStreamToken(id)
          if (response.success) {
            setIsConnected(true) 
            return response.token
          }
        }
      }
      else {
        setIsConnected(true)
        return chatClient.devToken(id)
      }
    }

    await chatClient.setUser( // connect or initialize admin
      {
        id: id,
        name: name,
      },
      await getTrainerToken()
    );
  }

  const filters = { type: 'messaging', members: { $in: [`${id}`]} };
  const sort = {
    last_message_at: -1, // newest first
    cid: 1,
  };
  const options = {
    member: true,
    watch: true,
    limit: 5,
  };

  useEffect(() => {
    init();
    return () => {
      chatClient.disconnect() // disconnect stream user connection on unmount
      setIsConnected(false)
    };
  }, [])

  return (
    <div className="columns" style={{ margin: '4% 1% 4% 1%' }}>
      <div className="column">
        <article className="panel is-link" style={{ maxHeight: '70vh' }}>
          <div className='admin-messages'>
            {isConnected && (
              <Chat client={chatClient}>
                <ChannelList
                  List={ChannelListMessenger}
                  Preview={ChannelPreviewMessenger}
                  filters={filters}
                  options={options}
                  sort={sort}
                />
                <Channel>
                  <Window>
                    <MessageList />
                    <MessageInput />
                  </Window>
                  <Thread />
                </Channel>
              </Chat>
            )}
          </div>
        </article>
      </div>
    </div>

  )
}

export default AdminMessages;
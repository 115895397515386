import React, { Component } from 'react';
import UserContext from '../../../context/user/UserContext';
import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';

class ProfilePage extends Component {
    componentDidMount() {
        if(window.ga) {
            window.ga('set', 'page', '/profile');
            window.ga('send', 'pageview');
          }
    }

    render() {
      return (
        <UserContext.Consumer>
        { userContext => (
            <section id="profile" className="section gradient-cover">
                <div className="container-fluid">
                    <div className="columns is-centered">
                        <div className="column is-half has-text-centered">
                            <figure className="image is-128x128 is-inline-block">
                                <img className="is-rounded" src={!_.isEmpty(userContext.user.profile.image) ? userContext.user.profile.image : "https://bulma.io/images/placeholders/256x256.png"} alt="profile" />
                            </figure>
                            <h4 className="title is-4">{userContext.user.profile.name}</h4>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-one-third">
                            <div className="">
                                <div className="box-content">
                                    <h5 className="title is-5">Contact Info</h5>
                                    <ul>
                                        <li>Email: <strong>{userContext.user.email}</strong></li>
                                        <li>Password: ***************</li>
                                        <li><button onClick={userContext.logout} className="button is-danger">Sign Out</button></li>
                                        <li><Link className="button is-primary" to="/change-password">Change Password</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="box-wrap">
                                <div>
                                    <h4 className="title is-4">
                                        <React.Fragment>
                                            My Plan: {!_.isEmpty(userContext.user.plans[0]) ? userContext.user.plans[0].label : ''}
                                        </React.Fragment>
                                    </h4>
                                    {!_.isEmpty(userContext.user.subscription) ?
                                        <React.Fragment>
                                            <h5 className="title is-5">Current Plan</h5>
                                            <p><b>Start:</b> {userContext.user.subscription.startPlan_date}</p>
                                            <p><b>End:</b> {userContext.user.subscription.endPlan_date}</p>
                                            <p>{userContext.user.subscription.interval === 'year' ? 'Yearly' : 
                                                userContext.user.subscription.interval === 'month' ? 'Monthly' : 
                                                    userContext.user.subscription.interval === 'week' ? 'Weekly' :
                                                    'Daily'}</p>
                                        </React.Fragment>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )}
        </UserContext.Consumer>
      );
    }
}

ProfilePage.contextType = UserContext;
export default withRouter(ProfilePage);
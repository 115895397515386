import React, { Component } from 'react';

class ShopPage extends Component {
    componentDidMount() {
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          this.ShopifyBuyInit();
        } else {
          this.loadScript();
        }
      } else {
        this.loadScript();
      }
    }

    loadScript = () => {
      var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
      var script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      script.onload = this.ShopifyBuyInit;
    }

    ShopifyBuyInit = () => {
      var client = window.ShopifyBuy.buildClient({
        domain: process.env.REACT_APP_SHOPIFY_DOMAIN,
        storefrontAccessToken: process.env.REACT_APP_SHOPIFY_STORE_FRONT_ACCESS_TOKEN,
      });
      window.ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('collection', {
          id: process.env.REACT_APP_SHOPIFY_COMPONENT_ID,
          node: document.getElementById('collection-component-1569533659344'),
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
            "product": {
              "styles": {
                "product": {
                  "@media (min-width: 601px)": {
                    "max-width": "calc(25% - 20px)",
                    "margin-left": "20px",
                    "margin-bottom": "50px",
                    "width": "calc(25% - 20px)"
                  },
                  "img": {
                    "height": "calc(100% - 15px)",
                    "position": "absolute",
                    "left": "0",
                    "right": "0",
                    "top": "0"
                  },
                  "imgWrapper": {
                    "padding-top": "calc(75% + 15px)",
                    "position": "relative",
                    "height": "0"
                  }
                }
              },
              "text": {
                "button": "Add to cart"
              }
            },
            "productSet": {
              "styles": {
                "products": {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px"
                  }
                }
              }
            },
            "modalProduct": {
              "contents": {
                "img": false,
                "imgWithCarousel": true,
                "button": false,
                "buttonWithQuantity": true
              },
              "styles": {
                "product": {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0px",
                    "margin-bottom": "0px"
                  }
                }
              },
              "text": {
                "button": "Add to cart"
              }
            },
            "cart": {
              "text": {
                "total": "Subtotal",
                "button": "Checkout"
              }
            }
          },
        });
      });
    }

    render() {
      return (
        <div className="columns is-centered">
          <div className="column is-half">
            <h4 className="title is-4">Shop</h4>
            <div id='collection-component-1569533659344'></div>
          </div>
        </div>
      );
    }
}

export default ShopPage;
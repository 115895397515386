import React, { Component } from 'react';
import UserContext from '../../../context/user/UserContext';
import _ from 'lodash';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: '',
            resetting: false,
            message: ''
        }
    }

    componentDidMount() {
        if(window.ga) {
            window.ga('set', 'page', '/reset-password');
            window.ga('send', 'pageview');
        }
    }

    setEmail = (e) => {
        this.setState({ email: e.target.value });
    }

    reset = async (e) => {
        e.preventDefault();
        this.setState({resetting: true, error: '', message: ''});
        let response = await this.context.resetPassword(this.state.email);
        if(!response.success) {
            this.setState({error: response.error});
        }
        else {
            this.setState({message: response.message});
        }

        this.setState({resetting: false});
    }

    render() {
      return (
          <React.Fragment>
              <div className="gradient-cover"></div>
              <section id="reset-password" className="section columns is-centered">
                <div className="column is-half">
                    <h4 className="title is-4">Reset Password</h4>
                    <form onSubmit={this.reset}>
                        <div className="field">
                            <label className="label">Email Address</label>
                            <div className="control has-icons-left">
                            <input className={`input ${!_.isEmpty(this.state.error) ? 'is-danger' : ''}`} type="email" value={this.state.email} onChange={this.setEmail} id="reset-email" placeholder="Enter email" required />
                            <span className="icon is-small is-left">
                                <i className="fa fa-envelope"></i>
                            </span>
                            </div>
                        </div>
                        <button type="submit" disabled={this.state.resetting} className={`button is-primary ${this.state.resetting ? 'is-loading' : ''}`}>Reset Password</button>
                    </form>
                    <p className="has-text-success">{this.state.message}</p>
                    <p className="has-text-danger">{this.state.error}</p>
                </div>
            </section>
          </React.Fragment>
      );
    }
}

ResetPassword.contextType = UserContext;
export default ResetPassword;
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from 'react-router-dom';

import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

if(process.env.REACT_APP_BUGSNAG_KEY) {
    var bugsnagClient = bugsnag({ apiKey: process.env.REACT_APP_BUGSNAG_KEY, notifyReleaseStages: [ 'production', 'staging' ], releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE });

    bugsnagClient.use(bugsnagReact, React);
    
    var ErrorBoundary = bugsnagClient.getPlugin('react');
    
    ReactDOM.render(<ErrorBoundary><Router><App /></Router></ErrorBoundary>, document.getElementById('root'));
}
else {
    ReactDOM.render(<Router><App /></Router>, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

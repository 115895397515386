import React, { Component } from 'react';

import TodaysWorkout from './todaysWorkout';
import MyProgress from './myProgress';
import Messages from './messages';
import UserContext from '../../../context/user/UserContext';
import { Link } from 'react-router-dom';
import _ from 'lodash';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matchHeight: 0
    }
  }
    componentDidMount() {
      window.scrollTo(0, 0);
      if(window.ga) {
        window.ga('set', 'page', '/account');
        window.ga('send', 'pageview');
      }
    }
    
    render() {
      return (
      <UserContext.Consumer>
        { userContext => (
          <React.Fragment>
            <section className="section gradient-cover">
              <div id="today">
              {!userContext.noPlan ?
                <div className="container-fluid">
                  <div className="columns">
                      <div className="column has-text-centered">
                        <h4 className="title">
                          <React.Fragment>
                              {!_.isEmpty(userContext.user.plans[0]) ? userContext.user.plans[0].label : ''}
                          </React.Fragment>
                        </h4>
                        <section id="progress">
                          <div className="columns">
                            <div className="column">
                              <MyProgress />
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div> : 
                  <React.Fragment>
                    <div className="container-fluid">
                      <div className="columns">
                        <div className="column has-text-centered">
                          <div className="notification is-success todays-workout pull-top-30">
                            We've received your questionnaire!
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>}
                  <div className="grey-bg-force">
                    <div className="container-fluid today-event">
                      <div className="columns">
                        <div className="column no-pad-right">
                            <TodaysWorkout />
                        </div>
                        <div className="messages column no-pad-left" style={{maxWidth: '400px'}}>
                          <h4 className="title pink">Messages</h4>
                          <Messages/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="cta-area">
                <div className="container-fluid">
                  <div className="columns is-vcentered is-centered">
                    <div className="column is-10 has-text-centered">
                      <div className="cta-box">
                        <p className="large-headline">
                          NUTRITION AND MEAL PLANNING
                        </p>
                        <p>Check out my favorite meal plans and recipes, included with your membership :)</p>
                        <Link to="/meal-plan" className="button is-large">Discover Meal Planning</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
          </React.Fragment>)}
        </UserContext.Consumer>
      );
    }
}

export default Account;
import axios from 'axios';

const apiPrefix = 'api';

const Constants = {
    URL_SIGN_IN: '/user/login',
    URL_REGISTER: '/user',
    URL_SIGN_IN_FB: '/user/loginFB',
    URL_SIGN_IN_GOOGLE: '/user/loginGoogle',
    URL_USER_PROFILE: '/user/profile',
    URL_USER_FORGOT_PASSWORD: '/user/forgotpass',
    URL_USER_CHANGE_PASSWORD: '/user/changepass',
    URL_EXERCISE_NOTES: '/user/exercise_notes/:exerciseId',
    URL_EXERCISE_NOTES_ADD: '/user/exercise_notes',
    URL_PLANS: '/trainer/plan',
    URL_PLAN: '/trainer/plan/:planId',
    URL_CUSTOMPLANS: '/user/custom_plan',
    URL_CUSTOMPLAN: '/user/customplan/:planId',
    URL_CREATE_STREAM_USER: '/pusher/create_user',
    URL_CHANGE_EXERCISE_WEIGHT: '/user/exercise_weight',
    URL_CHANGE_EXERCISE_REPS: '/user/exercise_reps',
    URL_ONBOARDING_QUESTIONS: '/user/questions',
    URL_ONBOARDING_QUESTIONS_UPDATE: '/user/save_question_answer',
}

class P3 {
    constructor() {
        this.apiClient = axios.create({
            baseURL: `${process.env.REACT_APP_API_URL}/${apiPrefix}`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        this.apiClient.interceptors.response.use((response) => {
            return response;
        }, function (error) {
            if (error.message === 'Network Error') {
                return Promise.resolve({ status: 'fail', success: false, message: 'Network Error' });
            }

            switch (error.response.status) {
                case 413:
                    return Promise.resolve(error.response);
                case 500:
                    return Promise.resolve(error.response);
                case 401:
                    return Promise.resolve(error.response);
                default:
                    return Promise.resolve(error.response);
            }
        });
    }

    getStatus() {
        return this.apiClient.get().then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    register(name, email, password) {
        let payload = { name, email, password };
        payload.trainer_id = process.env.REACT_APP_TRAINER_ID;
        payload.course_type = 'custom';

        return this.apiClient.put(Constants.URL_REGISTER, payload).then(response => {
            return Promise.resolve(response.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    signIn(email, password) {
        let payload = { email, password };
        payload.trainer_id = process.env.REACT_APP_TRAINER_ID;
        payload.course_type = 'custom';

        return this.apiClient.post(Constants.URL_SIGN_IN, payload).then(response => {
            return Promise.resolve(response.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    signInFB(email, facebook_id, image, phone = null) {
        let payload = { email, facebook_id, image, phone };
        payload.trainer_id = process.env.REACT_APP_TRAINER_ID;
        payload.course_type = 'custom';

        return this.apiClient.post(Constants.URL_SIGN_IN_FB, payload).then(response => {
            return Promise.resolve(response.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    signInGoogle(name, email, google_id, image, phone = null) {
        let payload = { name, email, google_id, image, phone };
        payload.trainer_id = process.env.REACT_APP_TRAINER_ID;
        payload.course_type = 'custom';

        return this.apiClient.post(Constants.URL_SIGN_IN_GOOGLE, payload).then(response => {
            return Promise.resolve(response.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    resetPassword(email) {
        return this.apiClient.post(Constants.URL_USER_FORGOT_PASSWORD, { email }).then(response => {
            return Promise.resolve(response.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    changePassword(current_pass, new_pass, token) {
        let payload = { current_pass, new_pass };
        return this.apiClient.post(Constants.URL_USER_CHANGE_PASSWORD + '?token=' + token, payload).then(response => {
            return Promise.resolve(response.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    getUser(token) {
        let params = {
            params: {
                'token': token
            }
        };

        return this.apiClient.get(Constants.URL_USER_PROFILE, params).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    updateUser(token, data) {
        return this.apiClient.post(Constants.URL_USER_PROFILE + `?token=${token}`, data).then(response => {
            return Promise.resolve(response.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    getNotes(token, exerciseId) {
        return this.apiClient.get(Constants.URL_EXERCISE_NOTES.replace(':exerciseId', exerciseId) + `?token=${token}&trainer_id=${process.env.REACT_APP_TRAINER_ID}`).then(response => {
            return Promise.resolve(response.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    addNote(token, userId, exerciseId, note) {
        let data = {
            notes: note,
            trainer_id: process.env.REACT_APP_TRAINER_ID,
            workout_id: exerciseId,
            user_id: userId,
            posted_by: 'user'
        };

        return this.apiClient.post(Constants.URL_EXERCISE_NOTES_ADD + `?token=${token}`, data).then(response => {
            return Promise.resolve(response.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    getCustomPlans(token) {
        let params = {
            params: {
                'token': token,
                'trainer_id': process.env.REACT_APP_TRAINER_ID
            }
        };

        return this.apiClient.get(Constants.URL_CUSTOMPLANS, params).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    getCustomPlan(token, planId) {
        let params = {
            params: {
                'token': token,
                'trainer_id': process.env.REACT_APP_TRAINER_ID
            }
        };

        return this.apiClient.get(Constants.URL_CUSTOMPLAN.replace(':planId', planId), params).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    getStreamToken(userId) {
        let data = {
            user_id: userId,
        };

        return this.apiClient.post(Constants.URL_CREATE_STREAM_USER, data).then(response => {
            return Promise.resolve(response.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    updateExerciseWeight(token, workoutId, userWeight) {
        let data = {
            workout_id: workoutId,
            changed_by: "user",
            user_weight: userWeight
        };

        return this.apiClient.post(Constants.URL_CHANGE_EXERCISE_WEIGHT + `?token=${token}`, data).then(response => {
            return Promise.resolve(response.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    updateExerciseReps(token, workoutId, userReps) {
        let data = {
            workout_id: workoutId,
            changed_by: "user",
            user_repeat: userReps
        };

        return this.apiClient.post(Constants.URL_CHANGE_EXERCISE_REPS + `?token=${token}`, data).then(response => {
            return Promise.resolve(response.data);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    getOnboardingInfo(token) {
        let params = {
            params: {
                'token': token,
                'trainer_id': process.env.REACT_APP_TRAINER_ID
            }
        };

        return this.apiClient.get(Constants.URL_ONBOARDING_QUESTIONS, params).then(response => {
            return Promise.resolve(response);
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    updateOnboarding(token, user_id, questions) {
        questions.forEach(question => {
            let data = {
                user_id: user_id,
                question_id: question._id,
                user_answer: question.user_answer
            }

            this.apiClient.post(Constants.URL_ONBOARDING_QUESTIONS_UPDATE + `?token=${token}`, data).then(response => {
                return Promise.resolve(response.data);
            }).catch(error => {
                return Promise.reject(error);
            });
        });
    }

}

const p3 = new P3();

export default p3;
export { Constants, apiPrefix };
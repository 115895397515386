import React, { Component } from 'react';

class Tos extends Component {
    componentDidMount() {
      window.scrollTo(0, 0);
    }

    render() {
      return (
          <section className="section hero is-small gradient-cover">
            <div className="hero-body">
              <div className="container">
                <h1 className="title">
                  Terms of Use
                </h1>
                <p>Thanks for using the {process.env.REACT_APP_SITE_NAME}, “{process.env.REACT_APP_SITE_NAME}”. “{process.env.REACT_APP_SITE_NAME}” is a partnership between Zoe Rodriguez and Plankk Technologies Inc. By using {process.env.REACT_APP_SITE_NAME}, you have understood and accepted the terms and conditions (Terms of Use).</p>
                <br /><h5 className="title is-5">BASIC TERMS OF USE</h5>
                <ol>
                    <li> You are fully responsible for the information and any activity that occurs under your user account.</li>
                    <li> You are fully responsible for protecting the password that you use to access {process.env.REACT_APP_SITE_NAME} and for any activities made under your password.</li>
                    <li> You must not abuse, harass, threaten, impersonate or intimidate other {process.env.REACT_APP_SITE_NAME} users.</li>
                    <li> You may not use {process.env.REACT_APP_SITE_NAME} for any illegal or unauthorized purpose.</li>
                    <li> International users agree to comply with all local laws regarding online conduct and acceptable content.</li>
                    <li> You are solely responsible for your conduct and any data, information, names, photos, profile information, links that you submit, post and display on {process.env.REACT_APP_SITE_NAME}.</li>
                    <li> You must not copy, create a derivative work of, nor modify another app to falsely imply that it is associated with {process.env.REACT_APP_SITE_NAME} or any of the {process.env.REACT_APP_SITE_NAME} products or services.</li>
                    <li> You must not interfere with or disrupt {process.env.REACT_APP_SITE_NAME} servers or networks such as the transmission of worms, viruses, or any code of a destructive nature applicable local, state, national, or international law.</li>
                    <li> You must not, in using {process.env.REACT_APP_SITE_NAME}, intentionally or unintentionally violate any application local, state, national, or international law.</li>
                </ol>
                <p>Violation of any of these agreements will result in the termination of your The {process.env.REACT_APP_SITE_NAME} App account.</p>

                <br /><h5 className="title is-5">GENERAL CONDITIONS</h5>
                <ol>
                    <li> We reserve the right to modify or terminate the {process.env.REACT_APP_SITE_NAME} service for any reason, without notice at any time.</li>
                    <li> {process.env.REACT_APP_SITE_NAME} reserves the right to change or modify these Terms of User at any time. Your continued use of {process.env.REACT_APP_SITE_NAME} will constitute your acceptance of such changes or modifications, what constitutes a “material change” will be determined at our sole discretion, in good faith and using common sense and reasonable judgment.</li>
                    <li> We reserve the right to refuse service to anyone for any reasonable judgment.</li>
                    <li> We may, but have no obligations to remove content and accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, and libelous. Defamatory, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Use.</li>
                    <li> As a condition of your use of {process.env.REACT_APP_SITE_NAME}, you agree that we have the right, for any purpose, to retain, use and publish in an aggregate manner information collected from your user of the app.</li>
                </ol>

                <br /><h5 className="title is-5">SUBSCRIPTION AND CANCELLATION</h5>
                <ol>
                    <li> If your subscription membership purchase was made through Apple’s IAP (in app purchase) function or Google’s Google Play Store (in app purchase) and if you wish to terminate your subscription, you are required to do so via Apple’s or Google’s subscription platform.</li>
                    <li> If your subscription membership purchase was made through zbody.plankk.com and if you wish to terminate your subscription, you are required to do so via zbody.plankk.com/Account/Login.</li>
                    <li> All purchases are final and no refund will be given for unused portions of your initial or any renewal membership period.</li>
                </ol>

                <br /><h5 className="title is-5">INDEMNITY</h5>
                <ol>
                    <li> You agree to indemnify {process.env.REACT_APP_SITE_NAME} on a full indemnity basis from any and all third party claims, liabilities, expenses including solicitor and client costs, that may be incurred or suffered as a result of a breach to any of these conditions set out in this Terms of Use by you or any third party damage caused as a result.</li>
                    <li> You agree to indemnify {process.env.REACT_APP_SITE_NAME} from any liability, claim, harm, or expense arising from an injury or health problem that may arise from the use of {process.env.REACT_APP_SITE_NAME}.</li>
                </ol>

                <br /><h5 className="title is-5">DISCLAIMER</h5>
                <p>The techniques and suggestions presented in The {process.env.REACT_APP_SITE_NAME} Application are not intended to substitute for proper fitness trainer, health professional or any and all medical advice. All fitness content and exercises have been created for information purposes only and you recognize that {process.env.REACT_APP_SITE_NAME} is not liable for any injuries or harm occurred by used of the exercises fitness information provided. Consult your physician before beginning any new exercise program. The The {process.env.REACT_APP_SITE_NAME} Application assumes no responsibility for injuries suffered while practicing these techniques.</p>

                <br /><h5 className="title is-5">COPYRIGHT</h5>
                <p>All the resources including the images, videos, audios and texts are protected by the trademark and copyright. Any use of the above sources without permission is illegal. All right, title, and interest in and to {process.env.REACT_APP_SITE_NAME} (excluding Content provided by users) are and will remain the exclusive property of {process.env.REACT_APP_SITE_NAME} and its licensors. Nothing in the Terms of Use gives you a right to use the {process.env.REACT_APP_SITE_NAME} name or any of the {process.env.REACT_APP_SITE_NAME} trademarks, logos, domain names, images, videos, audios and texts and other distinctive brand features.</p>
              </div>
            </div>
          </section>
      );
    }
}

export default Tos;
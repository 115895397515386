import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';

import UserContext from '../../../context/user/UserContext';

class MyProgress extends Component {
  async componentDidMount() {
    await setTimeout(() => this.progresScroll(), 1500);
  }

  async componentDidUpdate() {
    await setTimeout(() => this.progresScroll(), 1500);
  }

  progresScroll = () => {
    let lastComplete = document.getElementById('latest-complete');
    if (lastComplete !== null) {
      lastComplete.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'end' });
    }
  }

  goBack = () => {
    var el = document.getElementsByClassName('scroll-tracker');
    if (el.length) {
      if (el[0].scrollLeft - 300 >= -100) {
        el[0].scrollTo({
          top: 0,
          left: el[0].scrollLeft - 300,
          behavior: "smooth"
        });
      }
    }
  }

  goForward = () => {
    var el = document.getElementsByClassName('scroll-tracker');
    if (el.length) {
      if (el[0].scrollLeft + 300 <= el[0].scrollWidth) {
        el[0].scrollTo({
          top: 0,
          left: el[0].scrollLeft + 300,
          behavior: "smooth"
        });
      }
    }
  }


  renderProgressElements = (user) => {
    let key = 'savedData_' + user.plans[0]._id;
    if (_.has(user.profile, key)) {
      // Progress exists. Lets check it out
      JSON.parse(user.profile[key]).forEach(workoutDay => {
        if (_.find(user.plans[0].workoutdays, { _id: workoutDay._id }) && workoutDay.workoutComplete === 'Yes') {
          // This workout is complete lets mark it
          let item = _.find(user.plans[0].workoutdays, { _id: workoutDay._id });
          item.completed = true;
          item.completed_on = workoutDay.dateWithYear;
          let index = _.findIndex(user.plans[0].workoutdays, { _id: workoutDay._id });
          user.plans[0].workoutdays[index] = item;
        }
      });
    }

    let noProgress = false;

    let completedIndex = user.plans[0].workoutdays.length - 1 - user.plans[0].workoutdays.slice().reverse().findIndex((o) => o.completed);

    if (completedIndex === user.plans[0].workoutdays.length) {
      noProgress = true;
    }

    return user.plans[0].workoutdays.map((workout, index) => {

      const isToday = () => {
        if ((completedIndex + 1 === index) || (noProgress && index === 0)) {
          if ((noProgress && index === 0)) {
            return true
          }
          if (
            (user.plans[0].workoutdays[completedIndex])
            && (completedIndex + 1 === index)
            && moment().isAfter(moment(user.plans[0].workoutdays[completedIndex].completed_on), 'day')) {
            return true
          }
        };
      };

      const isTomorrow = () => {
        if (
          (user.plans[0].workoutdays[completedIndex])
          && (completedIndex + 1 === index)
          && moment().isSame(moment(user.plans[0].workoutdays[completedIndex].completed_on), 'day')) {
          return true
        };
      };

      const later = () => {
        if ((completedIndex + 1 < index)) {
          if (moment().isSame(moment(user.plans[0].workoutdays[completedIndex].completed_on), 'day')) {
            return moment().add(index - completedIndex, 'days').format('MMM D')
          }
          else {
            return moment().add(index - completedIndex - 1, 'days').format('MMM D')
          }
        }
        else {
          return moment().add(index, 'days').format('MMM D')
        }
      };

      return (
        <li key={workout._id} id={`${completedIndex + 1 === index ? 'latest-complete' : ''}`} className={`steps-segment ${completedIndex + 1 === index || (noProgress && index === 0) ? 'is-active' : ''}`}>
          <a href="#!" className="has-text-dark">
            <div className="clear"></div>
            <span className={`steps-marker`}>
              <span className={`icon has-text-white`}>
                <i className={`${workout.completed ? 'fa fa-check' : ''}`}></i>
              </span>
            </span>
          </a>
          <div className="steps-content has-text-centered">
            <p className={`heading ${workout.completed || completedIndex + 1 === index || (noProgress && index === 0) ? '' : 'incomplete'}`}>
              {workout.label}
              <span>{workout.completed ? moment(workout.completed_on).format('MMM D') :
                isToday() ? "Today" : isTomorrow() ? moment().add(index - completedIndex, 'days').format('MMM D') : later()
              }</span>
            </p>
          </div>
        </li>);
    })
  }

  render() {
    return (
      <UserContext.Consumer>
        {userContext => (
          !userContext.noPlan ?
            <section id="progress">
              <div className="container-fluid">
                <div className="columns">
                  <button onClick={this.goBack} className="button progress-button">
                    <span className="icon is-large">
                      <i className="fas fa-3x fa-chevron-left"></i>
                    </span>
                  </button>
                  <div className="column scroll-tracker">
                    <ul className="steps is-narrow is-medium has-content-centered">
                      {!_.isEmpty(userContext.user) && !_.isEmpty(userContext.user.plans) ?
                        this.renderProgressElements(userContext.user) : '...Loading'}
                    </ul>
                  </div>
                  <button onClick={this.goForward} className="button progress-button">
                    <span className="icon is-large">
                      <i className="fas fa-3x fa-chevron-right"></i>
                    </span>
                  </button>
                </div>
              </div>
            </section> :
            <section className="section container">
              <div className="columns">
                <div className="column">
                  <p>No Progress to report just yet! Just wait until your killing it.</p>
                </div>
              </div>
            </section>
        )}
      </UserContext.Consumer>
    );
  }
}

MyProgress.contextType = UserContext;
export default MyProgress;
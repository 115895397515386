import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import Reaptcha from 'reaptcha';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';

import UserContext from '../../context/user/UserContext';

class Register extends Component {
    constructor(props) {
        super(props);
  
        this.stripeResult = React.createRef();

        this.state= {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          emailConfirmation: '',
          password: '',
          passwordConfirm: '',
          recaptcha: false,
          errors: [],
          error: '',
          registering: false,
          loggingInFacebook: false,
          loggingInGoogle: false,
          stripeReturn: !_.isEmpty(props.match.params.payResult) ? props.match.params.payResult : '',
          selectedPlan: '',
          noSelection: false,
          redirecting: false
        }
      }

      setFirstName = (e) => {
        this.setState({firstName: e.target.value});
      }

      setLastName = (e) => {
        this.setState({lastName: e.target.value});
      }
  
      setEmail = (e) => {
        this.setState({email: e.target.value});
      }

      setEmailConfirmation = (e) => {
        this.setState({emailConfirmation: e.target.value});
      }
  
      setPassword = (e) => {
        this.setState({password: e.target.value});
      }

      setPasswordConfirm = (e) => {
        this.setState({passwordConfirm: e.target.value});
      }

      setPhoneNumber = (e) => {
        this.setState({phone: e.target.value});
      }

      onVerify = recaptchaResponse => {
        this.setState({
          recaptcha: true
        });
      };

      facebookResponse = async (response) => {
        await this.setState({loggingInFacebook: true});
        if(response.status !== 'unknown') {
          // Redirect to stripe before creating account
          this.setState({email: response.email});
          if(!_.isEmpty(this.state.selectedPlan)){
            localStorage.setItem('pcc-facebookUser', JSON.stringify(response));
            this.setState({ redirecting: true });
            this.context.cleverTapEvent('Pre Signup', {email: this.state.email});
            this.context.goToCheckout(this.state.selectedPlan, this.state.email);
          }
          else {
            this.setState({ noSelection: true , redirecting: false});
          }
        }
        else {
          this.setState({loggingInFacebook: false});
        }
      }

      googleResponse = async (response) => {
        await this.setState({loggingInGoogle: true});
        if(_.isEmpty(response.error)){
          // Redirect to stripe before creating account
          this.setState({email: response.profileObj.email});
          if(!_.isEmpty(this.state.selectedPlan)){
            localStorage.setItem('pcc-googleUser', JSON.stringify(response));
            this.setState({ redirecting: true });
            this.context.cleverTapEvent('Pre Signup', {email: this.state.email});
            this.context.goToCheckout(this.state.selectedPlan, this.state.email);
          }
          else {
            this.setState({ noSelection: true , redirecting: false});
          }
        }
        else {
          await this.setState({loggingInGoogle: false});
        }
        
      }
  
      register = async (e) => {
        e.preventDefault();
        this.setState({registering: true, errors: [], error: ''});

        if(this.state.email !== this.state.emailConfirmation) {
          await this.setState(prevState => ({
            errors: [...prevState.errors, {'email': 'Emails do not match'}]
          }));
        }
        else if(this.state.password !== this.state.passwordConfirm) {
          await this.setState(prevState => ({
            errors: [...prevState.errors, {'password': 'Passwords do not match'}]
          }));
        }
        else {
          await this.setState({errors: []});
        }


        if(this.state.errors.length === 0) {
          if(!_.isEmpty(this.state.selectedPlan)){
            localStorage.setItem('pcc-regUser', JSON.stringify(this.state));
            this.setState({ redirecting: true });
            this.context.cleverTapEvent('Pre Signup', {email: this.state.email});
            this.context.goToCheckout(this.state.selectedPlan, this.state.email);
          }
          else {
            this.setState({ noSelection: true , redirecting: false});
          }
        }

        this.setState({registering:false});
      }

      async componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({stripeReturn: !_.isEmpty(this.props.match.params.payResult) ? this.props.match.params.payResult : ''});

        if(this.state.stripeReturn === 'success') {
          if(!_.isEmpty(localStorage.getItem('pcc-googleUser'))){
            // Google post subscription
            let prePostUser = JSON.parse(localStorage.getItem('pcc-googleUser'));
            let p3Response = await this.context.loginGoogle(prePostUser.profileObj.name, prePostUser.profileObj.email, prePostUser.profileObj.googleId, prePostUser.profileObj.imageUrl);
            if(!p3Response.success) {
              await this.setState({logginInGoogle: false, error: p3Response.error});
            }
            else {
              await this.setState({logginInGoogle: false});
              localStorage.removeItem('pcc-googleUser');
              this.context.cleverTapProfile(prePostUser.profileObj.givenName, prePostUser.profileObj.familyName, prePostUser.profileObj.email)
              this.context.cleverTapEvent('Signup');
              this.props.history.push('/onboard');
            }
          }
          else if(!_.isEmpty(localStorage.getItem('pcc-facebookUser'))){
            let prePostUser = JSON.parse(localStorage.getItem('pcc-facebookUser'));
            let p3Response = await this.context.loginFB(prePostUser.email, prePostUser.userID, prePostUser.picture.data.url);
            if(!p3Response.success) {
              await this.setState({loggingInFacebook: false, error: p3Response.error});
            }
            else {
              await this.setState({loggingInFacebook: false});
              localStorage.removeItem('pcc-facebookUser');
              this.context.cleverTapProfile(prePostUser.name, null, prePostUser.email);
              this.context.cleverTapEvent('Signup');
              this.props.history.push('/onboard');
            }
          }
          else if(!_.isEmpty(localStorage.getItem('pcc-regUser'))){
            // Registered User
            let prePostUser = JSON.parse(localStorage.getItem('pcc-regUser'));
            let name = prePostUser.firstName + ' ' + prePostUser.lastName;
            let result = await this.context.signup(name, prePostUser.email, prePostUser.password, prePostUser.phone);
            if(!result.success) {
              this.setState({error: result.error});
            }
            else {
              localStorage.removeItem('pcc-regUser');
              this.context.cleverTapProfile(prePostUser.firstName, prePostUser.lastName, prePostUser.email, prePostUser.phone);
              this.context.cleverTapEvent('Signup');
              this.props.history.push('/onboard');
            }
          }
          else {
            // User already should be logged in so just redirect
            this.props.history.push('/onboard');
          }
        }
        
        // Successful payment. Register user and redirect to onboard
        await new Promise(r => setTimeout(r, 10000));

        if(window.ga) {
          window.ga('set', 'page', '/sign-up');
          window.ga('send', 'pageview');
        }
      }

      removeNotification = () => {
        this.stripeResult.current.classList.toggle('is-hidden');
      }

      selectPlan = async (e) => {
        await this.setState({selectedPlan: e.target.value});
        if(_.isEmpty(this.state.selectedPlan)) {
            this.setState({noSelection: false});
        }
      
        document.getElementById('register-form').scrollIntoView({
            behavior: 'smooth'
        });
      }
  
      render() {
        return (
            <React.Fragment>
          <section className="section sign-in gradient-cover">
            <div className="container-fluid">
              <div className="columns">
                <div className="column">
                  <div ref={this.stripeResult} className={`notification ${!_.isEmpty(this.state.stripeReturn) ? 'is-active' : 'is-hidden'} ${!_.isEmpty(this.state.stripeReturn) && this.state.stripeReturn === 'success' ? 'is-success' : 'is-danger'}`}>
                      <button className="delete" onClick={this.removeNotification}></button>
                      {!_.isEmpty(this.state.stripeReturn) && this.state.stripeReturn === 'success' ?
                      <React.Fragment>
                          Thank you for starting your custom training plan!. You will be redirected to your account page in 10 seconds.. <br /><Link to="/account">Get Started!</Link>
                      </React.Fragment>
                      : 
                      <React.Fragment>Something went wrong. Stripe reported an error. Please double check your payment details. If this error persists please contact <strong><a href="mailto:support@plankk.com">support@plankk.com</a></strong></React.Fragment>}
                  </div>
                  <div className={`notification is-danger ${!_.isEmpty(this.state.error) ? 'is-active' : 'is-hidden'}`}>
                      <button className="delete" onClick={this.removeNotification}></button>
                      {this.state.error}
                  </div>
                </div>
              </div>
                <div className="columns">
                  <div className="column">
                  </div>
                  <div className="column no-pad-left no-pad-right">
                    <div className="signup-box">
                      <div className="columns is-multiline">
                        <div className="column is-12">
                          <h1 className="title max-400">Ready to <br />crush our <br />goals together?</h1>
                          <p>Access a fully custom plan designed for you, my favorite meal plans and recipes - all with me and my team's support along the way!</p>
                        </div>
                        <div className="column is-12">
                          <p className={` select-plan ${this.state.noSelection ? 'has-text-danger' : ''}`}>Select a plan:</p>                      
                          <div className="control" onChange={this.selectPlan}>
                              <div className="columns">
                                  <div className={`column box ${this.state.selectedPlan === process.env.REACT_APP_STRIPE_SUB_MONTHLY ? 'has-background-primary has-text-white' : ''}`}>
                                      <label className="radio">
                                        <div className="spot"></div>
                                          <input type="radio" value={process.env.REACT_APP_STRIPE_SUB_MONTHLY} name="subscription" />
                                          <i>Monthly</i> <span>${process.env.REACT_APP_STRIPE_SUB_MONTHLY_PRICE}</span>
                                      </label>
                                  </div>
                              </div>
                              <div className="columns">
                                  <div className={`column box ${this.state.selectedPlan === process.env.REACT_APP_STRIPE_SUB_MONTHLY_EARLY_BIRD ? 'has-background-primary has-text-white' : ''}`}>
                                      <label className="radio early">
                                        <div className="spot"></div>
                                          <input type="radio" value={process.env.REACT_APP_STRIPE_SUB_MONTHLY_EARLY_BIRD} name="subscription" />
                                            <i>Early Bird Monthly</i> 
                                            <span>
                                              ${process.env.REACT_APP_STRIPE_SUB_MONTHLY_EARLY_BIRD_PRICE}<br /> <b>(Save $150)</b>
                                            </span>
                                      </label>
                                  </div>
                              </div>
                          </div>
                          <p className="has-text-danger">{this.context.stripeError}</p>
                        </div>
                      </div>
                        <div id="register-form" className={` ${_.isEmpty(this.state.selectedPlan) ? 'is-hidden' : null}`}>
                          <div className="columns">
                            <div className="column">
                            <FacebookLogin
                              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                              autoLoad={false}
                              disableMobileRedirect={true}
                              fields="name,email,picture"
                              callback={this.facebookResponse}
                              render={renderProps => (
                                <button className={`button is-fullwidth is-large ${this.state.loggingInFacebook ? 'is-loading' : ''}`} onClick={renderProps.onClick} disabled={this.state.loggingInFacebook}>
                                    <span className="icon is-medium">
                                    <i className="fab fa-facebook-f"></i>
                                  </span>
                                  <span>Sign up with Facebook</span>
                                </button>
                              )} />
                            </div>
                            <div className="column">
                              <GoogleLogin
                                clientId={process.env.REACT_APP_GOOGLE_APP_ID}
                                render={renderProps => (
                                  <button className={`button is-fullwidth is-large ${this.state.loggingInGoogle ? 'is-loading' : ''}`} onClick={renderProps.onClick} disabled={this.state.loggingInGoogle}>
                                      <span className="icon is-medium">
                                      <i className="fab fa-google"></i>
                                    </span>
                                    <span>Sign up with Google</span>
                                  </button>
                                )}
                                onSuccess={this.googleResponse}
                                onFailure={this.googleResponse} />
                            </div>
                          </div>
                          <div className="or">
                            <div className="columns">
                              <div className="column">
                                <hr />
                              </div>
                              <div className="column is-1">
                                <p>
                                  OR
                                </p>
                              </div>
                              <div className="column hidden-xs">
                                <hr />
                              </div>
                            </div>
                          </div>
                          <form onSubmit={this.register}>
                            <div className="columns">
                              <div className="column field">
                                  <label className="label gone">First Name</label>
                                  <div className="control has-icons-left">
                                    <input className="input" type="text" id="register-firstName" value={this.state.firstName} onChange={this.setFirstName} placeholder="First Name" required />
                                    <span className="icon is-small is-left">
                                      <i className="fa fa-user"></i>
                                    </span>
                                  </div>
                              </div>
                              <div className="column field">
                                  <label className="label gone">Last Name</label>
                                  <div className="control has-icons-left">
                                    <input className="input" type="text" id="register-lastName" value={this.state.lastName} onChange={this.setLastName} placeholder="Last Name" required />
                                    <span className="icon is-small is-left">
                                      <i className="fa fa-user"></i>
                                    </span>
                                  </div>
                              </div>
                            </div>
                            <div className="columns">
                              <div className="column field">
                                  <label className="label gone">Phone Number</label>
                                  <div className="control has-icons-left">
                                    <input className="input" type="tel" id="register-phone" value={this.state.phone} onChange={this.setPhoneNumber} placeholder="Phone Number" title="[Country Code][10 Digit Phone Number]" pattern="[0-9]{1}[ -]?[0-9]{3}[ -]?[0-9]{3}[ -]?[0-9]{4}" required />
                                    <span className="icon is-small is-left">
                                      <i className="fa fa-phone"></i>
                                    </span>
                                  </div>
                              </div>
                            </div>
                            <div className="columns">
                              <div className="column field">
                                  <label className="label gone">Email Address</label>
                                  <div className="control has-icons-left">
                                    <input className={`input ${this.state.errors.some(error => error.hasOwnProperty("email")) ? 'is-danger' : ''}`} type="email" id="register-email" value={this.state.email} onChange={this.setEmail} placeholder="Email Address" required />
                                    <span className="icon is-small is-left">
                                      <i className="fa fa-envelope"></i>
                                    </span>
                                  </div>
                              </div>
                              <div className="column field">
                                  <label className="label gone">Email Confirmation</label>
                                  <div className="control has-icons-left">
                                    <input className={`input ${this.state.errors.some(error => error.hasOwnProperty("email")) ? 'is-danger' : ''}`} type="email" id="register-email-confirmation" value={this.state.emailConfirmation} onChange={this.setEmailConfirmation} placeholder="Confirm Email Address" required />
                                    <span className="icon is-small is-left">
                                      <i className="fa fa-envelope"></i>
                                    </span>
                                  </div>
                                  <p>{this.state.errors.some(error => error.hasOwnProperty("email")) ? <span className="has-text-danger">{this.state.errors.find(error => {return error.hasOwnProperty("email")}).email}</span> : ''}</p>
                              </div>
                            </div>
                            <div className="columns">
                              <div className="column field">
                                  <label className="label gone">Password</label>
                                  <div className="control has-icons-left">
                                    <input className={`input ${this.state.errors.some(error => error.hasOwnProperty("password")) ? 'is-danger' : ''}`} type="password" id="regiser-password" value={this.state.password} onChange={this.setPassword} placeholder="Password" required />
                                    <span className="icon is-small is-left">
                                      <i className="fa fa-lock"></i>
                                    </span>
                                  </div>
                              </div>
                              <div className="column field">
                                  <label className="label gone">Password Confirmation</label>
                                  <div className="control has-icons-left">
                                    <input className={`input ${this.state.errors.some(error => error.hasOwnProperty("password")) ? 'is-danger' : ''}`} type="password" id="regiser-passwordConfirmation" value={this.state.passwordConfirm} onChange={this.setPasswordConfirm} placeholder="Confirm Password" required />
                                    <span className="icon is-small is-left">
                                      <i className="fa fa-lock"></i>
                                    </span>
                                  </div>
                                  <p>{this.state.errors.some(error => error.hasOwnProperty("password")) ? <span className="has-text-danger">{this.state.errors.find(error => {return error.hasOwnProperty("password")}).password}</span> : ''}</p>
                              </div>
                            </div>
                            <div className="columns is-centered">
                              <div className="column is-half has-text-centered">
                                <Reaptcha sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onVerify={this.onVerify} />
                              </div>
                            </div>
                            <div className="columns">
                              <div className="column is-full is-centered has-text-centered">
                                <button type="submit" disabled={this.state.registering || !this.state.recaptcha || _.isEmpty(this.state.selectedPlan)} className={`button is-large is-fullwidth is-primary ${this.state.registering || this.state.redirecting ? 'is-loading' : ''}`}>Let's Do This!</button>
                              </div>
                            </div>
                          </form>
                          <div className="columns">
                            <div className="column is-centered has-text-centered">
                              <p>Already have an account? <Link to="sign-in">Sign In Here</Link></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              </section>
            </React.Fragment>
        );
      }
}

Register.contextType = UserContext;
export default withRouter(Register);
import React, { useState, useContext, useEffect } from 'react';
import { Chat, Channel, Thread, Window } from 'stream-chat-react';
import { MessageList, MessageInput } from 'stream-chat-react';
import { StreamChat } from 'stream-chat';
import UserContext from '../../../context/user/UserContext';
import 'stream-chat-react/dist/css/index.css';
import _ from 'lodash';
import P3 from '../../../utils/P3';

const chatClient = new StreamChat(process.env.REACT_APP_STREAM_CHAT_KEY);

const Messages = () => {

  const { ...userContext } = useContext(UserContext);
  const [state, setState] = useState({channel: {}, streamToken: '' });
  const [adminOnline, setAdminOnline] = useState(false)
  const userName = userContext.user.profile.name ? userContext.user.profile.name : '';
  const userImage = userContext.user.profile.image ? userContext.user.profile.image : '';

  const getTrainerID = async () => { // check if trainer id exists in stream users list and return
    let userArray = await chatClient.queryUsers({ id: { $autocomplete: process.env.REACT_APP_TRAINER_ID } })
    if (userArray.users.length > 0) {
      if (userArray.users[0].online) { // check if trainer online
        setAdminOnline(true)
      }
      return userArray.users[0].id
    }
  };

  const initializeStreamChat = async () => {

    const token = process.env.NODE_ENV === 'production' ?
      userContext.user.profile.stream_chat_token ? userContext.user.profile.stream_chat_token :
        await P3.getStreamToken(userContext.user._id).then(async (response) => { if (response.success) return response.token })
      : chatClient.devToken(userContext.user._id)

    chatClient.setUser( // open stream API connection
      {
        id: userContext.user._id,
        name: `${userName}`,
        image: userImage
      },
      token
    );
    const trainerID = await getTrainerID();
    const filter = { type: 'messaging', members: { $in: [`${userContext.user._id}`] } };
    const request = await chatClient.queryChannels(filter)
    const isActiveChannel = request.length > 0 ? true : false

    if (trainerID) { // if no channel and user_id, trianer_id available 
      let channel;
      if (!isActiveChannel) {
        channel = chatClient.channel('messaging', userContext.user._id, {
          name: `${process.env.REACT_APP_SITE_NAME} - ${userName}`,
          image: userImage,
          members: [userContext.user._id, trainerID],
        })
      } else {
        channel = request[0]
        // if any of the current data doesn't match the existing channel's data then update 
        if ((channel.data.name !== `${process.env.REACT_APP_SITE_NAME} - ${userName}`)
          || (channel.data.image !== userImage)
          || (channel.state.members && channel.state.members[trainerID].user.id !== trainerID)) {
          await channel.update(
            {
              name: `${process.env.REACT_APP_SITE_NAME} - ${userName}`,
              image: userImage,
              members: [userContext.user._id, trainerID],
            }, { text: 'ROOM UPDATED' }
          );
          await channel.addMembers([`${trainerID}`]);
        }
      }
      setState({ ...state, channel: channel, isEmpty: _.isEmpty((await channel.watch()).messages) })
    }
    else { // if no channel and only user_id available 
      let channel;
      if (!isActiveChannel) {
        channel = chatClient.channel('messaging', userContext.user._id, {
          name: `${process.env.REACT_APP_SITE_NAME} - ${userName}`,
          image: userImage,
          members: [userContext.user._id, trainerID],
        })
      } else {
        channel = request[0]
        // if any of the current data doesn't match the existing channel's data then update
        if ((channel.data.name !== `${process.env.REACT_APP_SITE_NAME} - ${userName}`)
          || (channel.data.image !== userImage)) {
          await channel.update(
            {
              name: `${process.env.REACT_APP_SITE_NAME} - ${userName}`,
              image: userImage,
              members: [userContext.user._id],
            },
            { text: 'ROOM UPDATED' },
          );
        }
      }
      setState({ ...state, channel: channel, isEmpty: _.isEmpty((await channel.watch()).messages) })
    }

  };

  // create or check for channel updates
  useEffect(() => {
    async function init() { await initializeStreamChat() };
    init();
    return () => {
      chatClient.disconnect() // disconnect stream user connection on unmount
    };
  }, []);

  return (
    <div className="box-wrap">
      <p>{adminOnline ? '' : <a href={`${process.env.REACT_APP_TRAINER_SCHEDULING_PAGE}`} target="_blank" rel="noopener noreferrer" className="schedule-chat">Schedule Chat</a>}</p>
      <Chat client={chatClient} theme={'messaging light'}>
        <Channel channel={state.channel} >
          <Window >
            {!state.isEmpty ? <MessageList /> : <div className="str-chat__list" style={{ color: 'black' }}><p className="str-chat__message-text-inner str-chat__message-simple-text-inner">Start a conversation!</p></div>}
            <MessageInput />
          </Window>
          <Thread />
        </Channel>
      </Chat>
    </div>
  );
};

export default Messages;

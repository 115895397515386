import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Privacy extends Component {
    componentDidMount() {
      window.scrollTo(0, 0);
    }

    render() {
      return (
          <section className="section hero is-small gradient-cover">
            <div className="hero-body">
              <div className="container">
                <h1 className="title">
                  Privacy Policy
                </h1>
                <h2 className="subtitle">
                  Important things
                </h2>
                <p>This {process.env.REACT_APP_SITE_NAME} Privacy Policy ("Privacy Policy") describes how we collect and use your information through digital means. By accessing or using this mobile application you consent to our Privacy Policy. The {process.env.REACT_APP_SITE_NAME} is a partnership between Zoe Rodriguez and Global Fitness Creative Inc. and will be known as “{process.env.REACT_APP_SITE_NAME}” in this Privacy Policy. {process.env.REACT_APP_SITE_NAME} may modify this Privacy Policy at any time effective upon its posting. Your continued use of our products and services constitutes your acceptance to this Privacy Policy and any updates. This Privacy Policy is incorporated into, and is subject to, the <Link to="/terms">Terms of Use.</Link></p>
                
                <br /><h5 className="title is-5">WHAT INFORMATION DO YOU GIVE US?</h5>
                <p>We collect information you give us or permit us to access. Information may include, but is not limited to: social media profile information, name, email, fitness level, height, weight, gender, contacts, GPS location, and when necessary, credit card information.</p>
                
                <br /><h5 className="title is-5">WHAT INFORMATION DO WE COLLECT FROM YOU?</h5>
                <p>Information we may collect includes, but is not limited to, your telephone number, Device identifier, GPS location, cookie information, system type, information about your purchases and other information about your interactions with us. We may automatically collect information when you interact with the {process.env.REACT_APP_SITE_NAME} mobile application.</p>
                
                <br /><h5 className="title is-5">CHILDREN'S PRIVACY</h5>
                <p>We do not knowingly solicit or collect personal information from children under 13.</p>
                
                <br /><h5 className="title is-5">INFORMATION MAY BE USED TO OPERATE, IMPROVE AND ANALYZE USER EXPERIENCE</h5>
                <p>{process.env.REACT_APP_SITE_NAME} may use the information we collect to analyze user behavior and trends, send you administrative messages, send you in-app messages and other information about {process.env.REACT_APP_SITE_NAME}, and communicate with you.</p>
                
                <br /><h5 className="title is-5">INFORMATION MAY BE USED TO PROVIDE AND CONDUCT MARKETING AND PROMOTIONS</h5>
                <p>{process.env.REACT_APP_SITE_NAME} may use the information we collect to communicate with you about our products, services and events, promotions, surveys or other app features. Information may also be used to display relevant marketing material to you.</p>
                
                <br /><h5 className="title is-5">SHARING INFORMATION</h5>
                <p>{process.env.REACT_APP_SITE_NAME} may provide your information to Global Fitness Creative Inc. and their respective affiliates. We may also share your information to administer new app features (Ex: Fitbit API integration).</p>

                <br /><h5 className="title is-5">BY LAW OR TO PROTECT RIGHTS</h5>
                <p>We may share information to prevent illegal uses of the {process.env.REACT_APP_SITE_NAME} or violations of the <Link to="/terms">Terms of Use.</Link>, or to defend ourselves against third-party claims. We may disclose information upon governmental request, in response to a court order, when required by law, to enforce our policies, or to protect our or others' rights, property or safety.</p>

                <br /><h5 className="title is-5">TRANSFER OF OWNERSHIP OR ASSETS</h5>
                <p>Your information may be transferred to a third party as a part of our business assets in a sale of a part or all of the {process.env.REACT_APP_SITE_NAME} partnership owners.</p>

                <br /><h5 className="title is-5">SOCIAL MEDIA INFORMATION SHARING</h5>
                <p>If you choose to share your activity on other platforms, such as Facebook or other social media platforms, please read the privacy policies of those platforms. Any information about {process.env.REACT_APP_SITE_NAME} published on those platforms will no longer be governed by {process.env.REACT_APP_SITE_NAME}’s Privacy Policy.</p>

                <br /><h5 className="title is-5">{process.env.REACT_APP_SITE_NAME} ACCESS TO PUBLIC INFORMATION</h5>
                <p>Information that is publicly shared may be used by {process.env.REACT_APP_SITE_NAME} for marketing or promotional purposes.</p>

                <br /><h5 className="title is-5">PROTECTING INFORMATION</h5>
                <p>We use a variety of security measures, including encryption and authentication tools, to help protect your information. We use secure servers for your profile and subscription information to the app.</p>

                <br /><h5 className="title is-5">NO GUARANTEE</h5>
                <p>Like other companies, {process.env.REACT_APP_SITE_NAME} cannot guarantee 100% the security or confidentiality of the information you provide to us. We have put measures in place to do our best that your information security is not breached.</p>

                <br /><h5 className="title is-5">MODIFYING OR DELETING YOUR INFORMATION</h5>
                <p>You can modify or delete your profile information through your {process.env.REACT_APP_SITE_NAME} account. {process.env.REACT_APP_SITE_NAME} may keep information provided and consented upon in our backup files and archives.</p>

                <br /><h5 className="title is-5">OPT-OUT/ UNSUBSCRIBE</h5>
                <p>You may unsubscribe to email promotions and notifications by using the “Unsubscribe” link in the respective email or changing your settings. {process.env.REACT_APP_SITE_NAME} may still send you in-app notifications. You have the ability to toggle off notifications in your profile of the app.</p>

                <br /><h5 className="title is-5">INTERNATIONAL USERS</h5>
                <p>Regardless of where you live, you consent to have your personal data transferred, processed and stored in both the United States and Canada, and allow {process.env.REACT_APP_SITE_NAME} to use and collect your personal information in accordance with this Privacy Policy.</p>

                <br /><h5 className="title is-5">TERMS OF USE</h5>
                <p>Your use of the {process.env.REACT_APP_SITE_NAME} is subject to this Privacy Policy as well as our <Link to="/terms">Terms of Use.</Link></p>

                <br /><h5 className="title is-5">For questions or concerns, please contact <a href="mailto:info@plankk.com" rel="noopener noreferrer" target="_blank">info@plankk.com</a></h5>
              </div>
            </div>
          </section>
      );
    }
}

export default Privacy;
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';


class Onboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeQuestionIndex: 0,
      scrollPosition: 0,
      errors: []
    }

    this.scrollContainer = React.createRef();
  }
    componentDidMount() {
      if(window.ga) {
        window.ga('set', 'page', '/onboard');
        window.ga('send', 'pageview');
      }
    }

    onChoiceChange = async (e) => {
      let response = await this.props.updateOnboarding(e.target.id.split('-')[0], e.target.value);
      let answer = _.find(response.answers, (answer) => {
        return response.user_answer === answer.value;
      });

      if(!_.isEmpty(answer.skip)) {
        this.setQuestion(parseInt(answer.skip));
      } else {
        this.setQuestion(this.state.activeQuestionIndex < this.props.user.onboarding.length - 1 ? this.state.activeQuestionIndex+1 : this.props.user.onboarding.length - 1);
      }
      
    }

    onBooleanSelect = async (e) => {
      await this.props.updateOnboarding(e.target.dataset.id, e.target.value);
      this.setQuestion(this.state.activeQuestionIndex < this.props.user.onboarding.length - 1 ? this.state.activeQuestionIndex+1 : this.props.user.onboarding.length - 1);
    }

    onMultiChoiceChange = async (e) => {
      let values = [...e.target.parentElement.closest("form").getElementsByTagName("INPUT")].map(element => {
        if(element.tagName === 'INPUT' && element.checked) {
          return element.value;
        }

        return null;
      });

      await this.props.updateOnboarding(e.target.parentElement.closest("form").dataset.id, values.filter(Boolean));
    }

    onNextText = async (e) => {
      e.preventDefault();
      let text = e.target.elements[0].value;
      if(!_.isEmpty(text)) {
        // Text is ready
        await this.props.updateOnboarding(e.target.elements[0].dataset.id, text);
        this.setQuestion(this.state.activeQuestionIndex < this.props.user.onboarding.length - 1 ? this.state.activeQuestionIndex+1 : this.props.user.onboarding.length - 1);
      } else {
        this.setState(prevState => ({
          errors: [...prevState.errors, ]
        }))
      }
    }

    onboardComplete = async () => {
      await this.props.submitOnboarding();
      this.props.cleverTapEvent('Onboarded');
      this.props.history.push('/account');
    }

    onNext = async (e) => {
      e.preventDefault();

      let answers = this.props.user.onboarding[this.state.activeQuestionIndex].answers.filter(answer => {
        return _.includes(this.props.user.onboarding[this.state.activeQuestionIndex].user_answer, answer.value) && !_.isEmpty(answer.skip);
      });

      if(answers.length > 0 && !_.isEmpty(answers[0].skip)) {
        this.setQuestion(parseInt(answers[0].skip) - 1);
      } else {
        this.setQuestion(this.state.activeQuestionIndex < this.props.user.onboarding.length - 1 ? this.state.activeQuestionIndex+1 : this.props.user.onboarding.length - 1);
      }
    }

    renderAnswers = (question) => {
      switch(question.type) {
        case 'text':
          return (
            <React.Fragment>
              <form onSubmit={this.onNextText}>
                <input className="input" type="text" defaultValue={!_.isEmpty(this.props.user.onboarding.find(q=>q._id === question._id).user_answer) ? this.props.user.onboarding.find(q=>q._id === question._id).user_answer : ''} data-id={question._id} placeholder={question.placeholder} required /><br />
                {this.props.user.onboarding.findIndex((origQuestion) => origQuestion._id === question._id) !== this.props.user.onboarding.length - 1 ? <button className="button">Next</button> : null}
              </form>
            </React.Fragment>
          );
        case 'choice':
          return (
            question.answers.map((answer,index1) => {
              return (
                <div className="radio-wrap" key={index1}>
                  <input type="radio"
                    checked={!_.isEmpty(this.props.user.onboarding.find(q=>q._id === question._id).user_answer) && this.props.user.onboarding.find(q=>q._id === question._id).user_answer === answer.value ? this.props.user.onboarding.find(q=>q._id === question._id).user_answer : ''} 
                    onChange={this.onChoiceChange} 
                    name={question._id} 
                    id={question._id+'-'+index1} 
                    value={answer.value} 
                  />
                  <label className={`button is-info ${!_.isEmpty(question.user_answer) && question.user_answer === answer.value ? 'selected' : ''}`} htmlFor={question._id+'-'+index1}>  {answer.value}</label><br />
                </div>
              );
            })
          );
        case 'boolean':
          return (
            <React.Fragment>
              <div className="checkbox-wrap">
                <button data-id={question._id} onClick={this.onBooleanSelect} value="Yes" className={`button ${!_.isEmpty(question.user_answer) && question.user_answer === 'Yes' ? 'selected' : ''}`}>Yes</button><button data-id={question._id} onClick={this.onBooleanSelect} value="No" className={`button ${!_.isEmpty(question.user_answer) && question.user_answer === 'No' ? 'selected' : null}`}>No</button>
              </div>
            </React.Fragment>
          );

        case 'multichoice':
          return (
            <React.Fragment>
              <form data-id={question._id} onSubmit={this.onNext}>
                {question.answers.map((answer,index2) => {
                  return (
                    <React.Fragment key={index2}>
                      <div className={`checkbox ${!_.isEmpty(question.user_answer) && _.includes(question.user_answer, answer.value) ? 'selected' : ''}`}>
                        <input type="checkbox" onChange={this.onMultiChoiceChange} name={question._id} id={question._id+'-'+index2} value={answer.value} checked={!_.isEmpty(question.user_answer) && _.includes(question.user_answer, answer.value)}/><label htmlFor={question._id+'-'+index2}>  {answer.value}</label>
                      </div><br />
                    </React.Fragment>
                  );
                })}
                <button className="button">Next</button>
              </form>
            </React.Fragment>
          );
        default:
          return;
      }
    }

    setQuestion = (index) => {
      this.setState({activeQuestionIndex: index});

      this.scrollContainer.current.scrollTo({
        top: this[`${index}_question_ref`].current.offsetTop - 300,
        left: 0,
        behavior: 'smooth'
      });
    }

    render() {
      return (
        <React.Fragment>
          <div className="gradient-cover">
            <section id="onboard">
              <div className="has-text-centered">
                <div className="container">
                  <div className="columns is-centered">
                    <div className="column is-10">
                      <h1 className="pink">
                        AMAZING! I’m so happy that you have decided to start custom training with me!
                      </h1>
                      <p className="max-600">
                        First things first - I need to learn more about you and your goals. This questionnaire will take less than 10 minutes, I promise!
                      </p>
                    </div>
                  </div>
                 
                  <div ref={this.scrollContainer} className="questionnaire">
                    {!_.isEmpty(this.props.user.onboarding) ?
                      <React.Fragment>
                        {this.props.user.onboarding.map((question,index) => {
                          this[`${index}_question_ref`] = React.createRef();

                          return (
                          <div ref={this[`${index}_question_ref`]} id={question._id} className={`question ${index === this.state.activeQuestionIndex ? 'active' : ''}`} key={question._id}>
                            <p>{question.question}</p>
                            {this.renderAnswers(question)}
                          </div>);
                        })}
                        <div className="buttons has-addons">
                          <button disabled={this.state.activeQuestionIndex === 0} onClick={() => this.setQuestion(this.state.activeQuestionIndex-1)} className="button is-primary"><span className="icon is-small"><i className="fas fa-chevron-up"></i></span></button>
                          <button disabled={this.state.activeQuestionIndex === this.props.user.onboarding.length - 1} onClick={() => this.setQuestion(this.state.activeQuestionIndex+1)} className="button is-primary"><span className="icon is-small"><i className="fas fa-chevron-down"></i></span></button>
                        </div>
                        {(this.state.activeQuestionIndex === this.props.user.onboarding.length - 1) ? <div className="submit"><button onClick={this.onboardComplete} className="button is-primary">Let's Go!</button></div> : null}
                      </React.Fragment>
                    : <span className="icon">
                    <i className="fas fa-spinner fa-pulse"></i>
                  </span>}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </React.Fragment>
      );
    }
}

export default withRouter(Onboard);
import React, { Component } from 'react';
import UserContext from '../../../context/user/UserContext';

class MealPlanPage extends Component {
  
async componentDidMount() {
    if (window.ga) {
      window.ga('set', 'page', '/meal-plan');
      window.ga('send', 'pageview');
    }

    let header = document.querySelector("nav");
    let gradientCover = document.querySelector("div.gradient-cover");
    await header.classList.add("sticky");
    await gradientCover.classList.remove("gradient-cover");
    await gradientCover.classList.add("gradient-cover-meal");
  }

  menud = function () {
    return {
      __html: this.props.iframe
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="gradient-cover"></div>
        <section id="mealplan-page">
          <div className="container-embed">
            <iframe title="menud" src={process.env.REACT_APP_MENUD_EMBED_URL + `?authToken=${this.context.user.profile.stripe_menud_token}`} className="embedFrame"></iframe>
            <div>
              <div dangerouslySetInnerHTML={this.menud()} />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

MealPlanPage.contextType = UserContext;
export default MealPlanPage;

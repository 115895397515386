import React, { Component } from "react"
import WorkoutContext from '../../../context/workout/WorkoutContext';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import moment from "moment";

class ExerciseTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timerOn: false,
            timerOnInterval: '',
            countdown: 0,
            length: this.props.length > 0 ? this.props.length : 0
        }
    }

    componentDidMount() {
        this.setState(prevState => ({
            length: this.props.length,
            countdown: this.props.length,
            ...prevState
        }));

        if (this.props.autoStart && this.state.length > 0) {
            this.toggleTimer();
        }
    }

    toggleTimer = async () => {
        if (this.state.timerOn) {
            // Stop timer clear interval
            clearInterval(this.state.timerOnInterval);
            await this.setState({ timerOnInterval: '', countdown: this.state.length });
        }
        else {
            // Start timer set interval
            let timer = setInterval(async () => {
                await this.setState({ length: this.state.length - 1 });
                if (this.state.length === 0) {
                    // timer complete. Stop it
                    clearInterval(timer);
                    this.setState({ timerOn: false });

                    if (this.props.callBack) {
                        this.props.callBack();
                    }
                    if (!this.props.autoStart || this.props.singleExercise) {
                        await this.context.finishExercise(this.props.match.params.exercise);
                    }
                }
            }, 1000);

            await this.setState({ timerOnInterval: timer });
        }

        await this.setState({ timerOn: !this.state.timerOn });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.length !== this.props.length || (!_.isEmpty(prevProps.exerciseId) && prevProps.exerciseId !== this.props.exerciseId)) {
            // Updating
            if (this.state.timerOn || this.props.autoStart) {
                this.toggleTimer();
            }

            this.setState({ length: this.props.length });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="text-is-centered main-timer">
                    <p className="time-count">
                        {parseInt(this.state.length) > 0 ? 
                            parseInt(this.state.length) > 60 ? <React.Fragment>{moment.utc(parseInt(this.state.length * 1000)).format('mm:ss')} <span>remaining</span></React.Fragment> : <React.Fragment>{parseInt(this.state.length)} <span>sec.</span></React.Fragment>: <React.Fragment><span style={{top: '-35px', height: '152px', margin: 'auto', fontSize: '2.5rem'}}><strong>Done</strong></span></React.Fragment>}</p>
                    {!this.props.autoStart ? <button onClick={this.toggleTimer} disabled={parseInt(this.state.length) === 0 && !this.state.timerOn} className={`button is-fullwidth ${this.state.timerOn ? 'is-danger' : 'is-primary'}`}>{this.state.timerOn ? 'Stop' : 'Start'}</button> : ''}
                </div>
            </React.Fragment>
        );
    }

}

ExerciseTimer.contextType = WorkoutContext;
export default withRouter(ExerciseTimer);

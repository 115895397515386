import React, { Component } from 'react';
import netlifyIdentity from 'netlify-identity-widget';
import AdminMessages from './adminMessages';

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuth: false,
            user: {}
        }

        window.netlifyIdentity = netlifyIdentity;
        // You must run this once before trying to interact with the widget
        netlifyIdentity.init();
    }


    componentDidMount() {
        const user = netlifyIdentity.currentUser();
        if (user) {
            this.setState({ isAuth: true, user: user });
        }
        else {
            // netlifyIdentity.open();
        }

        netlifyIdentity.on('login', user => {
            this.setState({ isAuth: true, user: user });
            netlifyIdentity.close();
        });

        netlifyIdentity.on('logout', () => this.setState({ isAuth: false, user: {} }));
        netlifyIdentity.on('error', err => console.error('Error', err));
    }

    render() {
        return (
            <React.Fragment>
                <div className="gradient-cover"></div>
                <section className="section container">
                    <h4 className="title is-4">Admin</h4>
                    {this.state.isAuth ?
                        <section className="section container">
                            <h2>Welcome</h2>
                            <button className="button is-primary" onClick={() => netlifyIdentity.open()}>See Auth</button>
                            <button className="button is-danger" onClick={() => netlifyIdentity.logout()}>Logout</button>
                            <AdminMessages />
                        </section> :
                        <button className="button is-info" onClick={() => netlifyIdentity.open()}>Login</button>
                    }
                </section>
            </React.Fragment>
        );
    }
}

export default Admin;
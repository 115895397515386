import React, { Component } from 'react';
import WorkoutContext from '../../../context/workout/WorkoutContext';
import UserContext from '../../../context/user/UserContext';
import noPlanImage from '../../../assets/noPlan.jpg';
import moment from 'moment';

import _ from 'lodash';

class TodaysWorkout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    }
  }

  toggleModal = () => {
    if (this.state.modalOpen) {
      document.getElementsByTagName('html')[0].classList.remove('is-clipped');
    }
    else {
      document.getElementsByTagName('html')[0].classList.add('is-clipped');
    }

    this.setState({ modalOpen: !this.state.modalOpen });
  }

  generateString = () => {
    let workoutsArray = [];

    if (_.isEmpty(this.context.workout)) {
      return null;
    }


    this.context.workout.workout.children.forEach((workout, index) => {
      if (!_.isEmpty(workout.children)) {
        workout.children.forEach((subWorkout, index1) => {
          workoutsArray.push(subWorkout.label);
        });
      }
    });

    let description = workoutsArray.join(', ');
    console.log(description.length);

    return description.length > 120 ? description.substring(0, 120) + "... and more!" : description;
  }

  generateDescription = () => {

    if (!_.isEmpty(this.context.workout) && !_.isEmpty(this.context.workout.description)) {
      if (this.context.workout.description.length > 120) {
        return this.context.workout.description.substring(0, 120) + '... and more!';
      }
      else {
        return this.context.workout.description;
      }
    }
    if (!_.isEmpty(this.context.workout) && _.isEmpty(this.context.workout.description) && this.context.workout.type === 'rest') {
      return "Take the day off!"
    }
    else {
      return null;
    }
  }

  getEquipment = () => {
    let equipmentArray = [];
    this.context.workout.workout.children.forEach((workout, index) => {
      if (!_.isEmpty(workout.children)) {
        workout.children.forEach((subWorkout, index1) => {
          if (subWorkout.main_equipments.length > 0) {
            subWorkout.main_equipments.forEach(equipment => {
              if (!_.includes(equipmentArray, equipment.label)) {
                equipmentArray.push(equipment.label);
              }
            })
          }
        });
      }
    });

    return equipmentArray.length > 0 ? 'You\'ll need ' + equipmentArray.join(', ') : null;
  }



  isTodaysWorkout = (user) => {
    if (user.plans.length > 0) {
      let key = 'savedData_' + user.plans[0]._id
      if (_.has(user.profile, key)) {
        // Progress exists. Lets check it out
        JSON.parse(user.profile[key]).forEach(workoutDay => {
          if (_.find(user.plans[0].workoutdays, { _id: workoutDay._id }) && workoutDay.workoutComplete === 'Yes') {
            // This workout is complete lets mark it
            let item = _.find(user.plans[0].workoutdays, { _id: workoutDay._id });
            item.completed = true;
            item.completed_on = workoutDay.dateWithYear;
            let index = _.findIndex(user.plans[0].workoutdays, { _id: workoutDay._id });
            user.plans[0].workoutdays[index] = item;
          }
        });
      }
      let completedIndex = user.plans[0].workoutdays.length - 1 - user.plans[0].workoutdays.slice().reverse().findIndex((o) => o.completed);
      if (completedIndex === user.plans[0].workoutdays.length) {
        return true; // if no progress return it's todays workout
      }
      //check if the upcomming workout date is todays date
      else return moment().isAfter(moment(user.plans[0].workoutdays[completedIndex].completed_on), 'day')
    }
  }

  render() {
    return (

      <WorkoutContext.Consumer>
        {workoutContext => (
          <UserContext.Consumer>
            {userContext => (
              !userContext.noPlan ?
                <React.Fragment>
                  {this.isTodaysWorkout(userContext.user) ? <h4 className="title blue">Today's Workout</h4> : <h4 className="title blue">Tomorrow's Workout</h4>}
                  <div className="box-wrap workout-wrap" id="todays-workout" >
                    <div className="content" style={{ backgroundImage: !_.isEmpty(workoutContext.workout) && !_.isEmpty(workoutContext.workout.workout.image) ? `url("${process.env.REACT_APP_API_URL}/uploads/workouts/${workoutContext.workout.workout.image}")` : 'darkgrey', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'darkgrey' }}>
                      <div className="columns is-flex">
                        <div className="column">
                          <h1 className="title">
                            {!_.isEmpty(workoutContext.workout) ? workoutContext.workout.label : ''}
                          </h1>
                          <p>{this.generateDescription()}</p>
                          <p>{this.generateString()}</p>
                          {!_.isEmpty(workoutContext.workout) && this.isTodaysWorkout(userContext.user) && workoutContext.workout.type !== 'rest' ?
                            <button onClick={this.toggleModal} className="button is-primary is-large is-fullwidth">Start</button> : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="start-workout-modal" className={`modal ${this.state.modalOpen ? 'is-active' : ''}`}>
                    <div className="modal-background" onClick={this.toggleModal}></div>
                    <div className="modal-content">
                      <div className="box container">
                        {!_.isEmpty(workoutContext.workout) ?
                          <React.Fragment>
                            <section className="hero is-dark">
                              <div className="hero-body">
                                <div className="container">
                                  <h1 className="title">
                                    {workoutContext.workout.label}
                                  </h1>
                                </div>
                              </div>
                            </section>
                            <div className="container workout-info">
                              <p>{workoutContext.workout.description}</p><br />
                              <p>{this.getEquipment()}</p><br />
                              {workoutContext.workout.workout.children.map((exercise, key) => {
                                return (
                                  <React.Fragment key={exercise._id}>
                                    <h5>{parseInt(exercise.sets) > 0 ? exercise.sets + ' x ' : ''} {exercise.label}</h5>
                                    <div>
                                      <ul>
                                        {exercise.children.length > 0 ?
                                          exercise.children.map((subExercise, index) => {
                                            if (parseInt(subExercise.repeat) === 0 && _.isEmpty(subExercise.repeat_range)) {
                                              // Time'd Exercise
                                              let label = 'sec.';
                                              let num = 0;
                                              if (parseInt(subExercise.time_in_minutes) > 0) {
                                                num = parseInt(subExercise.time_in_minutes);
                                                label = 'min.';
                                              }
                                              else {
                                                num = parseInt(subExercise.time);
                                              }

                                              return <li key={index}>{num + ' ' + label} {subExercise.label}</li>;
                                            }
                                            else {
                                              // Reps / Weight Exercise
                                              return <li key={index}>{parseInt(subExercise.repeat) === 0 ? subExercise.repeat_range : subExercise.repeat} x {subExercise.label}</li>;
                                            }
                                          }) : null}
                                      </ul>
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </React.Fragment> :
                          <p>...Loading</p>}
                        <button className="modal-close is-large top-right-corner" onClick={this.toggleModal} aria-label="close"></button>
                        <button onClick={workoutContext.startWorkout} className="is-pulled-right button is-primary lower-right-corner">Start</button>
                      </div>
                    </div>
                  </div>
                </React.Fragment> :
                <React.Fragment>
                  <div className="box-wrap workout-wrap" id="todays-workout" >
                    <div className="content" style={{ backgroundImage: `url("${noPlanImage}")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'top center', backgroundColor: 'darkgrey' }}>
                      <div className="columns is-flex">
                        <div className="column has-text-centered">
                          <h1 className="title">
                            Welcome to {process.env.REACT_APP_SITE_NAME}.
                          </h1>
                          <p>Now that we have your initial questionaire answers, you will see an email in the next 24 hours to set up a time to meet and go over any additional questions we may have. Meet ups are easy, and will be in the online chat to the right.  Your fully custom plan will be ready the day after we meet :) Any other questions? Feel free to hit me up at <a href="mailto:zoe@trainwithzoe.com" rel="noopener noreferrer" target="_blank">zoe@trainwithzoe.com</a></p>
                          <a href="https://calendly.com/train-with-zoe" id="calender-schedule" target="_blank" rel="noopener noreferrer" className="button is-primary is-large">Schedule Kick Off</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
            )}
          </UserContext.Consumer>)}
      </WorkoutContext.Consumer>
    );
  }
}

TodaysWorkout.contextType = WorkoutContext;
export default TodaysWorkout;
import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';
import _ from 'lodash';
import Reaptcha from 'reaptcha';

import UserContext from '../../context/user/UserContext';
import { withRouter, Link } from 'react-router-dom';

class SignIn extends Component {
    constructor(props) {
      super(props);

      this.state= {
        email: '',
        password: '',
        recaptcha: false,
        signingIn: false,
        logginInFacebook: false,
        logginInGoogle: false,
        error: ''
      }
    }

    setEmail = (e) => {
      this.setState({email: e.target.value});
    }

    setPassword = (e) => {
      this.setState({password: e.target.value});
    }

    onVerify = recaptchaResponse => {
      this.setState({
        recaptcha: true
      });
    };

    login = async (e) => {
      e.preventDefault();
      this.setState({signingIn: true, error: ''});
      let result = await this.context.login(this.state.email, this.state.password);
      if(!result.success) {
        let error = result.error === 'Email/password is wrong!' ? "Oops! This doesn’t match our records - try again!" : result.error;
        this.setState({error: error});
      }
      else {
        this.props.history.push('/account');
      }

      this.setState({signingIn: false});
    }

    facebookResponse = async (response) => {
      await this.setState({logginInFacebook: true});
      if(response.status !== 'unknown') {
        let p3Response = await this.context.loginFB(response.email, response.userID, response.picture.data.url);
        if(!p3Response.success) {
          await this.setState({logginInFacebook: false, error: p3Response.error});
        }
        else {
          await this.setState({logginInGoogle: false});
          this.props.history.push('/account');
        }
      }
      else {
        await this.setState({logginInFacebook: false});
      }

    }

    googleResponse = async (response) => {
      await this.setState({logginInGoogle: true});
      if(_.isEmpty(response.error)){
        let p3Response = await this.context.loginGoogle(response.profileObj.name, response.profileObj.email, response.profileObj.googleId, response.profileObj.imageUrl);
        if(!p3Response.success) {
          await this.setState({logginInGoogle: false, error: p3Response.error});
        }
        else {
          await this.setState({logginInGoogle: false});
          this.props.history.push('/account');
        }
      }
      else {
        await this.setState({logginInGoogle: false});
      }
    }

    componentDidMount() {
      window.scrollTo(0, 0);
      if(window.ga) {
        window.ga('set', 'page', '/sign-in');
        window.ga('send', 'pageview');
      }
    }

    render() {
      return (
          <React.Fragment>
          <section className="section sign-in gradient-cover">
            <div className="container-fluid">
              <div className="columns">
                <div className="column"></div>
                <div className="column">
                  <div className="signup-box">
                    <div className="columns">
                      <div className="column">                    
                          <h1 className="title">LOGIN</h1>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <FacebookLogin
                          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                          autoLoad={false}
                          disableMobileRedirect={true}
                          fields="name,email,picture"
                          callback={this.facebookResponse} 
                          render={renderProps => (
                            <button className={`button is-fullwidth is-large ${this.state.logginInFacebook ? 'is-loading' : ''}`} onClick={renderProps.onClick} disabled={this.state.logginInFacebook}>
                                <span className="icon is-medium">
                                <i className="fab fa-facebook-f"></i>
                              </span>
                              <span>Log In with Facebook</span>
                            </button>
                          )}
                        />
                      </div>
                      <div className="column">
                      <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_APP_ID}
                        render={renderProps => (
                          <button className={`button is-fullwidth is-large ${this.state.logginInGoogle ? 'is-loading' : ''}`} onClick={renderProps.onClick} disabled={renderProps.disabled}>
                              <span className="icon is-medium">
                              <i className="fab fa-google"></i>
                            </span>
                            <span>Log In with Google</span>
                          </button>
                        )}
                        onSuccess={this.googleResponse}
                        onFailure={this.googleResponse} />
                      </div>
                    </div>
                    <div className="or">
                      <div className="columns">
                        <div className="column">
                          <hr />
                        </div>
                        <div className="column is-1">
                          <p>
                            OR
                          </p>
                        </div>
                        <div className="column hidden-xs">
                          <hr />
                        </div>
                      </div>
                    </div>
                    <form onSubmit={this.login}>
                      <div className="columns">
                        <div className="column">
                          <div className="field">
                            <label className="label gone">Email Address</label>
                            <div className="control has-icons-left">
                              <input className={`input ${!_.isEmpty(this.state.error) ? 'is-danger' : ''}`} type="email" value={this.state.email} onChange={this.setEmail} id="signin-email" aria-describedby="emailHelp" placeholder="Enter Email" required />
                              <span className="icon is-small is-left">
                                <i className="fa fa-envelope"></i>
                              </span>
                            </div>
                          </div>
                          <div className="field">
                            <label className="label gone">Password</label>
                            <div className="control has-icons-left">
                              <input className={`input ${!_.isEmpty(this.state.error) ? 'is-danger' : ''}`} type="password" value={this.state.password} id="signin-password" onChange={this.setPassword} placeholder="Password" required />
                              <span className="icon is-small is-left">
                                <i className="fa fa-lock"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column pad-top-0">
                          <div className="field">
                            <Reaptcha sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onVerify={this.onVerify} />
                          </div>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column">
                          <button type="submit" disabled={this.state.signingIn || !this.state.recaptcha} className={`button is-large is-fullwidth is-primary ${this.state.signingIn ? 'is-loading' : ''}`}>Log In</button>
                        </div>
                      </div>
                    </form>
                    <p className="has-text-danger">{this.state.error}</p>
                    <div className="columns">
                      <div className="column is-centered has-text-centered">
                        <br />
                        <p><Link to="/reset-password">Forgot your password?</Link> | <Link to="/sign-up">Not yet a member?</Link></p>
                      </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          </React.Fragment>
      );
    }
}

SignIn.contextType = UserContext;
export default withRouter(SignIn);
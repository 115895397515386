import React, { Component } from 'react';
import UserContext from '../../../context/user/UserContext';
import _ from 'lodash';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
            errors: [],
            error: '',
            changing: false,
            message: ''
        }
    }

    componentDidMount() {
        if(window.ga) {
            window.ga('set', 'page', '/change-password');
            window.ga('send', 'pageview');
        }
    }

    setOldPassword = (e) => {
        this.setState({ oldPassword: e.target.value });
    }

    setNewPassword = (e) => {
        this.setState({ newPassword: e.target.value });
    }

    setNewPasswordConfirm = (e) => {
        this.setState({ newPasswordConfirm: e.target.value });
    }

    changePassword = async (e) => {
        e.preventDefault();
        this.setState({changing: true, error: '', message: '', errors:[]});

        if(this.state.newPassword !== this.state.newPasswordConfirm) {
            await this.setState(prevState => ({
              errors: [...prevState.errors, {'password': 'Passwords do not match'}]
            }));
        }
        else {
            await this.setState({errors: []});
        }
  
        
        if(this.state.errors.length === 0) {
            let result = await this.context.changePassword(this.state.oldPassword, this.state.newPassword);
            if(!result.success) {
                this.setState({error: result.error});
            }
            else {
                this.setState({message: result.message});
            }
        }

        this.setState({changing: false});
    }

    render() {
      return (
          <React.Fragment>
              <div className="gradient-cover"></div>
              <section id="change-password" className="section columns is-centered">
                <div className="column is-half">
                    <h4 className="title is-4">Change Password</h4>
                    <form onSubmit={this.changePassword}>
                        <div className="field">
                            <label className="label">Old Password</label>
                            <div className="control has-icons-left">
                            <input className={`input ${!_.isEmpty(this.state.error) ? 'is-danger' : ''}`} type="password" value={this.state.oldPassword} onChange={this.setOldPassword} id="change-old-password" placeholder="Enter Old Password" required />
                            <span className="icon is-small is-left">
                                <i className="fa fa-lock"></i>
                            </span>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">New Password</label>
                            <div className="control has-icons-left">
                            <input className={`input ${this.state.errors.some(error => error.hasOwnProperty("password")) ? 'is-danger' : ''}`} type="password" value={this.state.newPassword} onChange={this.setNewPassword} id="change-new-password" placeholder="Enter New Password" required />
                            <span className="icon is-small is-left">
                                <i className="fa fa-lock"></i>
                            </span>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">New Password Confirmation</label>
                            <div className="control has-icons-left">
                            <input className={`input ${this.state.errors.some(error => error.hasOwnProperty("password")) ? 'is-danger' : ''}`} type="password" value={this.state.newPasswordConfirm} onChange={this.setNewPasswordConfirm} id="change-new-password-confirm" placeholder="Confirm New Password" required />
                            <span className="icon is-small is-left">
                                <i className="fa fa-lock"></i>
                            </span>
                            </div>
                        </div>
                        <p>{this.state.errors.some(error => error.hasOwnProperty("password")) ? <span className="has-text-danger">{this.state.errors.find(error => {return error.hasOwnProperty("password")}).password}</span> : ''}</p>
                        <button type="submit" disabled={this.state.changing} className={`button is-primary ${this.state.changing ? 'is-loading' : ''}`}>Change Password</button>
                    </form>
                    <p className="has-text-success">{this.state.message}</p>
                    <p className="has-text-danger">{this.state.error}</p>
                </div>
            </section>
          </React.Fragment>
      );
    }
}

ChangePassword.contextType = UserContext;
export default ChangePassword;
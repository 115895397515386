import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import HeroVideo from '../../../assets/hero_optimized.mp4';

import LandingLeft from '../../../assets/landingLeft.jpg';
import Devices from '../../../assets/devices1.png';

import Nutrition from '../../../assets/mealplanningNutrition.png';
import TrackProgress from '../../../assets/trackYourProgress.png';
import TwoWay from '../../../assets/twoWayCommunication.png';

import FooterLogo from '../../../assets/footerLogo.png';

import SpeechBubble from '../../../assets/Icons/Speech Bubble Icon.png';
import CheckMarkWhite from '../../../assets/Icons/Checkmark Icon.png';
import TrackingIcon from '../../../assets/Icons/Tracking Icon.png';
import Down from '../../../assets/Icons/down.png';

import XIcon from '../../../assets/Icons/X Icon.png';
import Pencil from '../../../assets/Icons/Pencil Icon.png';

import Before2 from '../../../assets/BeforeAfter/2.png';
import Before3 from '../../../assets/BeforeAfter/3.png';
import Before4 from '../../../assets/BeforeAfter/4.jpg';
import Before5 from '../../../assets/BeforeAfter/5.jpg';
import Before6 from '../../../assets/BeforeAfter/6.jpg';
import Before7 from '../../../assets/BeforeAfter/7.jpg';
import Before8 from '../../../assets/BeforeAfter/8.jpg';
import Before11 from '../../../assets/BeforeAfter/11.jpg';

import UserContext from '../../../context/user/UserContext';

import bulmaCarousel from 'bulma-carousel';

class LandingPage extends Component {
    componentDidMount() {
      bulmaCarousel.attach('.carousel', {loop: true, autoplay: true, duration: 700, autoplaySpeed: 8000});
      if(window.ga) {
        window.ga('set', 'page', '/');
        window.ga('send', 'pageview');
      }
    }
    render() {
      return (
        <div className="landing">
          <section className="section home-video-wrap">
            <video src={HeroVideo} className="video-background" autoPlay loop muted />
              <div className="container-fluid">
                <div className="columns is-vcentered is-centered">
                  <div className="column has-text-centered is-8">
                    <div className="video-content-wrap">
                      <h1 className="title">
                        Ready to train with me?
                      </h1>
                      <h2 className="subtitle">
                        Custom personal training plans created <span>just for you.</span>
                      </h2>
                    </div>
                </div>
              </div>
            </div>
          </section>

          <div className="scroll-more is-centered has-text-centered">
            <AnchorLink offset='200' href="#features">
              <img src={Down} alt="" />
            </AnchorLink>
          </div>

          <section id="banner" ref={this.banner} style={{top: this.context.isAuth ? '60px' : null}}>
            <div className="is-centered">
              <div className="column has-text-centered">
                <div className="columns is-vcentered is-mobile">
                  <div className="column has-text-right">
                    <span>SAVE $150.00 PER MONTH!</span>
                  </div>
                  <div className="column is-4">
                    <Link ref={this.bannerButton} className="button is-white is-medium" to="/sign-up">Let’s get started!</Link>
                  </div>
                  <div className="column has-text-left">
                    <span>FIRST 20 SPOTS ONLY!</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section grey-content">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-8 has-text-centered">
                  <br />
                  <p className="tagline">Why Custom Training?</p>
                  <h3 className="title">If you are ready to take your training to the next level, <strong className="has-text-weight-bold">this is the program for you!</strong> We create a training program specific to YOU - your body, your schedule, your goals.</h3>
                  <br /> 
              </div>
              </div>
            </div>
          </section>

          <div id="features">
            <div className="features-bg">
              <div className="columns is-marginless features is-vcentered">
                <div className="column is-half">
                  <div className="feature-image pink-image">
                    <img src={LandingLeft} alt="working out" />
                  </div>
                </div>
                <div className="column is-half has-text-left">
                  <div className="max-600">
                    <h5 className="featured-title pink-title">Features</h5>
                    <h3 className="main-title">Fully Custom Training Plan</h3>
                    <p>This isn’t a template program  - we build your program specifically for you! After learning about where and how you want to work out, we will build an exclusive program to help you achieve your goals.  But, the customization doesn’t stop there! We will continue to tweak and improve your program as you progress.</p>
                    <Link ref={this.bannerButton} className="button is-primary is-large is-pink" to="/sign-up">I'm Ready!</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="features-bg">
              <div className="columns is-marginless features is-vcentered">
              <div className="column is-half visible-xs">
                  <div className="feature-image">
                    <img src={TwoWay} alt="working out" />
                  </div>
                </div>
                <div className="column has-text-left is-half">
                  <div className="max-600">
                    <h5 className="featured-title">Features</h5>
                    <h3 className="main-title">Two-Way Communication</h3>
                    <p>When you join my custom training team, we have your back the whole time!  Have a question? Want a tweak to your program? No problem! You can interact directly with me and my team directly from your plan!</p>
                    <Link ref={this.bannerButton} className="button is-primary is-large" to="/sign-up">Get Started</Link>
                  </div>
                </div>
                <div className="column is-half hidden-xs">
                  <div className="feature-image">
                    <img src={TwoWay} alt="working out" />
                  </div>
                </div>
              </div>
            </div>
            <div className="features-bg">
              <div className="columns is-gapeless is-marginless features is-vcentered">
                <div className="column">
                  <div className="feature-image pink-image">
                    <img src={Nutrition} alt="working out" />
                  </div>
                </div>
                <div className="column has-text-left">
                  <div className="max-600">
                    <h5 className="featured-title pink-title">Features</h5>
                    <h3 className="main-title">Meal Planning and Nutrition</h3>
                    <p>You may only work out 3 times a week, but you eat 3 times a day.  If you want real results, you have to eat right as well!  We have included 6 of my own personal favorite meal plans for you to choose from! Our nutrition platform, powered by Menud, allows you to choose one of my meal plans and even make changes to the plan from my library of over 500 recipes!</p>
                    <Link ref={this.bannerButton} className="button is-primary is-large is-pink" to="/sign-up">Get Started</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="features-bg">
              <div className="columns is-gapeless is-marginless features is-vcentered">
              <div className="column visible-xs">
                  <div className="feature-image">
                    <img src={TrackProgress} alt="working out" />
                  </div>
                </div>
                <div className="column has-text-left">
                  <div className="max-600">
                    <h5 className="featured-title">Features</h5>
                    <h3 className="main-title">Track your Progress</h3>
                    <p>Not only do you have two way communication with me and my team, we have also created a progress tracking feature to help keep you motivated!</p>
                    <Link ref={this.bannerButton} className="button is-primary is-large" to="/sign-up">Get Started</Link>
                  </div>
                </div>
                <div className="column hidden-xs">
                  <div className="feature-image">
                    <img src={TrackProgress} alt="working out" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="cta-area" id="join">
            <div className="container-fluid">
              <div className="columns is-vcentered is-centered">
                <div className="column has-text-centered">
                  <img src={Devices} alt="devices" />
                </div>
                <div className="column has-text-centered">
                  <div className="cta-box">
                    <p className="large-headline">
                      Ready to train with me?
                    </p>
                    <p>What are you waiting for? Let’s get started!</p>
                    <br />
                    <p className="pricing"><strike>$349.99 / month</strike><br /><span>only $199.99 / month</span></p>
                    <br /><br />
                    <Link className="button is-large is-primary is-uppercase" to="/sign-up">Lock in your discount now!</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          

          <section id="pricing" className="section grey-content is-centered">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-half has-text-centered">
                  <p className="tagline">Why Train with Me?</p>
                  <h3 className="title">Here's how Zbody Fitness stacks up against a personal trainer.</h3>
                </div>
              </div>
              <div className="columns is-centered is-mobile pricing-chart">
                <div className="column is-offset-4 is-4 has-text-centered">
                  <img src={FooterLogo} alt="logo" height="45"/>
                  <h4 className="title is-uppercase">{process.env.REACT_APP_SITE_NAME}</h4>
                </div>
                <div className="column is-4 has-text-centered">
                  <img src={FooterLogo} alt="logo" className="opacity-0" />
                  <h4 className="title is-uppercase">Personal Trainer</h4>
                </div>
              </div>
            </div>
            <div className="pricing-spot">
              <div className="container">
                <div className="columns is-centered is-multiline is-mobile is-vcentered pricing-chart">
                  <div className="column has-text-centered column-one">
                    <div className="columns is-vcentered">
                      <div className="column">
                        <p>Cost</p>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <p>Comunication</p>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <p>Schedule</p>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <p>Tracking</p>
                      </div>
                    </div>
                    <div className="columns border-zero">
                    <div className="column min-height">
                        <p>Nutrition and Meal Planning</p>
                      </div>
                    </div>
                  </div>
                  <div className="column has-text-centered column-two">
                      <div className="columns is-multiline is-mobile">
                        <div className="column">
                          <p>&lt; $50/week</p>
                        </div>
                      </div>
                      <div className="columns is-multiline is-mobile">
                        <div className="column">
                          <img src={SpeechBubble} alt="dollar sign" />
                        </div>
                      </div>
                      <div className="columns is-multiline is-mobile">
                        <div className="column">
                          <p>On my schedule</p>
                        </div>
                      </div>
                      <div className="columns is-multiline is-mobile">
                        <div className="column">
                          <img src={TrackingIcon} alt="dollar sign" />
                        </div>
                      </div>
                      <div className="columns is-multiline is-mobile border-zero">
                      <div className="column min-height">
                          <img src={CheckMarkWhite} alt="dollar sign" />
                        </div>
                      </div>
                  </div> 
                  <div className="column has-text-centered is-mobile column-three">
                    <div className="columns">
                      <div className="column">
                      <p>$100+/session</p>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <img src={XIcon} alt="x icon" />
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <p>Around my trainers schedule</p>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <img src={Pencil} alt="pencil icon" />
                      </div>
                    </div>
                    <div className="columns border-zero">
                      <div className="column min-height">
                        <img src={XIcon} alt="x icon" />
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
            <br /><br />
          </section>

          <section id="testimonials" className="section grey-content white-content">
            <div className="columns is-centered is-vcentered">
              <div className="column is-three-fifths has-text-centered">
                <p className="tagline">REAL results!</p>
                <h3 className="title">Over 100,000 women strong!</h3>
              </div>
            </div>
            <div className="columns is-vcentered is-mobile">
              <div className="column is-3">
                <img src={Before11} alt="before 1" />
              </div>
              <div className="column is-3">
                <img src={Before2} alt="before 2" />
              </div>
              <div className="column is-3">
                <img src={Before3} alt="before 3" />
              </div>
              <div className="column is-3">
                <img src={Before4} alt="before 4" />
              </div>
            </div>
            <div className="columns is-vcentered is-mobile">
              <div className="column is-3">
                <img src={Before5} alt="before 5" />
              </div>
              <div className="column is-3">
                <img src={Before6} alt="before 6" />
              </div>
              <div className="column is-3">
                <img src={Before7} alt="before 7" />
              </div>
              <div className="column is-3">
                <img src={Before8} alt="before 8" />
              </div>
            </div>
          </section>
          <section className="cta-area alternate-bg">
            <div className="container-fluid">
              <div className="columns is-vcentered is-centered">
                <div className="column is-8 has-text-centered">
                  <div className="cta-box">
                    <p className="large-headline">
                      Ok... Enough thinking about it.
                    </p>
                    <p>                    
                      Ready to take the first step? Join my ZBody Custom Training now!
                    </p>
                    <Link to="sign-up" className="button is-large is-white is-uppercase">Start Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    }
}

LandingPage.contextType = UserContext;
export default LandingPage;
import React, { Component } from 'react';
import WorkoutContext from '../../../context/workout/WorkoutContext';
import { withRouter } from 'react-router-dom';

class ExerciseWeightSets extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSetIndex: 0,
            sets: this.props.sets,
            setsComplete: []
        };
    }

    updateWeight = (e) => {
        this.setState({ setWeight: e.target.value});
    }

    updateReps = (e) => {
        this.setState({ setReps: e.target.value});
    }

    nextSet = async () => {
        await this.setState(prevState => ({
            setsComplete: [...prevState.setsComplete, prevState.sets[this.state.activeSetIndex]],
            activeSetIndex: prevState.activeSetIndex + 1,
        }));

        if(this.state.setsComplete.length === this.state.sets.length) {
            await this.context.finishExercise(this.props.match.params.id);
        }
    }


    render() {
        return (
            <React.Fragment>
                <div className="weight-sets">
                    <div className="content">
                        <p><strong>{this.state.setsComplete.length} / {this.state.sets.length}</strong></p>
                    </div>
                    {this.state.setsComplete.map((set, index) => {
                        return (<div key={set.id} className="box complete">
                            <div className="content">
                                <p><strong>{set.weight} / {set.reps}</strong> - <i className="has-text-success fa fa-check"></i></p>
                            </div>
                        </div>);
                    })}
                    {this.state.sets.map((set, index) => {
                        return (<div key={set.id} className={`box ${this.state.activeSetIndex === index ? '' : 'is-hidden'}`}>
                            <div className="content">
                                <p><strong>{set.weight} / {set.reps}</strong></p>
                            </div>
                        </div>);
                    })}
                    {this.state.setsComplete.length < this.state.sets.length ? <button className="button is-danger" onClick={this.nextSet}>Next Set</button> : ''}
                </div>
            </React.Fragment>
        );
    }

}

ExerciseWeightSets.contextType = WorkoutContext;
export default withRouter(ExerciseWeightSets);

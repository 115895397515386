import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import _ from 'lodash';
import moment from 'moment';
import Shadow from '../../../assets/video-shadow.png';

import { withRouter } from 'react-router-dom';

import ExerciseTimer from './exerciseTimer';
import ExerciseWeightSets from './exerciseWeightSets';
import SuperSets from './superSets';
import ExerciseNotes from './exerciseNotes';

import WorkoutContext from '../../../context/workout/WorkoutContext';
import { CSSTransition } from 'react-transition-group';

class Exercise extends Component {
    constructor(props, context) {
        super(props);

        let exerciseIndex = !_.isEmpty(props.match.params.exercise) && !_.isEmpty(context.workout) && _.findIndex(context.workout.workout.children, { _id: props.match.params.exercise }) !== -1 ? _.findIndex(context.workout.workout.children, { _id: props.match.params.exercise }) : 0;

        this.state = {
            activeExerciseIndex: exerciseIndex,
            activeSubExerciseIndex: 0,
            showingTabPrefix: 'hidden',
            showingTabChangePrefix: 'visibilitychange',
            restModal: false,
            restTime: 0,
            notes: [],
            animating: true
        };
    }

    toggleRestModal = (time = 0) => {
        this.setState({ restTime: time, restModal: !this.state.restModal });
    }

    restTimeDone = () => {
        this.setState({ restTime: 0 });
        if (this.state.restModal) {
            this.toggleRestModal();
        }
    }

    handleVisibilityChange = () => {
        if (!document[this.state.showingTabPrefix]) {
            this.context.startStopWorkoutTotal();
        } else {
            this.context.startStopWorkoutTotal();
        }
    }

    handleKeyPress = (e) => {
        if (e.isTrusted && e.ctrlKey && e.key === 'n') {
            // Keyboard for Next exercise
            if (document.getElementById("next") !== null && !this.state.restModal) {
                document.getElementById("next").click();
            }
        }
    }

    async componentDidMount() {

        window.scrollTo(0, 0);
        this.context.startStopWorkoutTotal();
        let hidden, visibilityChange;
        if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }

        await this.setState({ showingTabPrefix: hidden, showingTabChangePrefix: visibilityChange })
        document.addEventListener(this.state.showingTabChangePrefix, this.handleVisibilityChange, false);
        document.addEventListener('keyup', this.handleKeyPress);

        if (window.ga) {
            window.ga('set', 'page', '/exercise/' + this.props.match.params.workoutday + '/' + this.props.match.params.exercise);
            window.ga('send', 'pageview');
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.match.params.exercise !== this.props.match.params.exercise) {
            let exerciseIndex = _.findIndex(this.context.workout.workout.children, { _id: this.props.match.params.exercise });
            await this.setState({ activeExerciseIndex: exerciseIndex, notes: [] });
            if (window.ga) {
                window.ga('set', 'page', '/exercise/' + this.props.match.params.workoutday + '/' + this.props.match.params.exercise);
                window.ga('send', 'pageview');
            }
        }
    }

    componentWillUnmount() {
        this.context.startStopWorkoutTotal();
        document.removeEventListener(this.state.showingTabChangePrefix, this.handleVisibilityChange);
        document.removeEventListener('keyup', this.handleKeyPress);
    }

    increaseSubExercise = async () => {
        if ((this.state.activeSubExerciseIndex + 1) >= this.context.workout.workout.children[this.state.activeExerciseIndex].children.length) {
            // New set. Check for rest timer
            if (!_.isEmpty(this.context.workout.workout.children[this.state.activeExerciseIndex].gap_between_sets) && this.context.workout.workout.children[this.state.activeExerciseIndex].gap_between_sets > 0) {
                this.toggleRestModal(parseInt(this.context.workout.workout.children[this.state.activeExerciseIndex].gap_between_sets));
            }
        }

        await this.setState(prevState => ({
            activeSubExerciseIndex: (prevState.activeSubExerciseIndex + 1) >= this.context.workout.workout.children[this.state.activeExerciseIndex].children.length ? 0 : prevState.activeSubExerciseIndex + 1,
            animating: !prevState.animating
        }));
    }

    renderExerciseDetails = () => {
        if (this.state.activeExerciseIndex >= 0 && !_.isEmpty(this.context.workout.workout.children)) {
            switch (this.context.workout.workout.children[this.state.activeExerciseIndex].type) {
                case 'sets':
                    return <ExerciseWeightSets sets={this.context.workout.workout.children[this.state.activeExerciseIndex].sets} />;
                case 'group':
                    if (this.context.workout.workout.children[this.state.activeExerciseIndex].children.length === 0) {
                        // Cardio timer although of type group
                        return <ExerciseTimer autoStart singleExercise length={this.context.workout.workout.children[this.state.activeExerciseIndex].time} />;
                    }
                    else {
                        return <SuperSets
                            increaseSubExerciseIndex={this.increaseSubExercise}
                            index={this.state.activeSubExerciseIndex}
                            parentIndex={this.state.activeExerciseIndex}
                            setCount={parseInt(this.context.workout.workout.children[this.state.activeExerciseIndex].sets)}
                            breakTime={this.context.workout.workout.gap_between_sets}
                            exercises={this.context.workout.workout.children[this.state.activeExerciseIndex].children}
                        />;
                    }
                default:
                    return 'Something went wrong';
            }
        }
        else {
            return 'No exercise details';
        }
    }

    renderVideoLink = (workout) => {
        if (_.isEmpty(workout.children)) {
            // Main exercise has no children
            return null;
        }

        if (_.isEmpty(workout.children[this.state.activeExerciseIndex].video)) {
            // No video at top level exercise
            if (_.isEmpty(workout.children[this.state.activeExerciseIndex].children) || _.isEmpty(workout.children[this.state.activeExerciseIndex].children[this.state.activeSubExerciseIndex].video)) {
                // Child workout also has no video
                return null;
            }
            else {
                return <ReactPlayer loop muted playing url={workout.children[this.state.activeExerciseIndex].children[this.state.activeSubExerciseIndex].video} controls width="100%" config={{ file: { attributes: { controlsList: 'nodownload' } } }} height="100%" />;
            }
        }
        else {
            return <ReactPlayer loop muted playing url={workout.children[this.state.activeExerciseIndex].video} controls width="100%" config={{ file: { attributes: { controlsList: 'nodownload' } } }} height="100%" />;
        }
    }

    render() {
        return (
            _.isEmpty(this.context.workout) ?
                null :
                <WorkoutContext.Consumer>
                    {workoutContext => (
                        <React.Fragment>
                            <section id="exercise-page">
                                <div className="gradient-cover video-page">
                                    <div className="video-wrap">
                                        <CSSTransition in={this.state.animating} timeout={300} unmountOnExit onExited={() => this.setState({ animating: !this.state.animating })} classNames="my-node">
                                            <React.Fragment>
                                                <div className="container-fluid">
                                                    <div className="columns">
                                                        <div className="column">
                                                            <div className="date-area-video">
                                                                {this.renderVideoLink(workoutContext.workout.workout)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="video-details">
                                                        <div className="columns is-mobile">
                                                            {this.renderExerciseDetails()}
                                                            {this.context.workout.workout.children.every(workout => workout.complete) ? <button onClick={workoutContext.finishWorkout} className="button is-primary is-fullwidth">Finish</button> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="columns time-wrap">
                                                    <CSSTransition in={this.state.animating} timeout={300} unmountOnExit onExited={() => this.setState({ animating: !this.state.animating })} classNames="my-node-shadow">
                                                        <img src={Shadow} alt="" className="video-shadow" />
                                                    </CSSTransition>
                                                    <div className="column">
                                                        <p className="total-time-count">{moment.utc(workoutContext.totalTime * 1000).format('mm:ss')}</p>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        </CSSTransition>
                                    </div>
                                </div>
                                <div className="columns container-fluid exercise-extras">
                                    <div className="column is-half">
                                        <div className="white-content">
                                            <h4>Steps</h4>
                                            <br />
                                            <ol type="1">
                                                {!_.isEmpty(this.context.workout.workout.children[this.state.activeExerciseIndex].children[this.state.activeSubExerciseIndex]) ?
                                                    this.context.workout.workout.children[this.state.activeExerciseIndex].children[this.state.activeSubExerciseIndex].steps.map(step => {
                                                        return !_.isEmpty(step.description) ? <li key={step.number}> {step.description}</li> : null;
                                                    }) : null}
                                            </ol>
                                            <h4>Tips</h4>
                                            <br />
                                            <ul>
                                                {!_.isEmpty(this.context.workout.workout.children[this.state.activeExerciseIndex].children[this.state.activeSubExerciseIndex]) ?
                                                    !_.isEmpty(this.context.workout.workout.children[this.state.activeExerciseIndex].children[this.state.activeSubExerciseIndex].tip) ?
                                                        <li>{this.context.workout.workout.children[this.state.activeExerciseIndex].children[this.state.activeSubExerciseIndex].tip}</li> : null : null}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="column is-5 is-offset-1">
                                        {_.isEmpty(this.context.workout.workout.children[this.state.activeExerciseIndex].children[this.state.activeSubExerciseIndex]) ?
                                            '...Loading' :
                                            <ExerciseNotes notes={this.state.notes} exerciseId={this.context.workout.workout.children[this.state.activeExerciseIndex].children[this.state.activeSubExerciseIndex]._id} />
                                        }
                                    </div>
                                </div>
                            </section>
                            {this.state.restModal ?
                                <div id="rest-timer-modal" className="modal is-active">
                                    <div className="modal-background" onClick={() => this.toggleRestModal()}></div>
                                    <div className="modal-content">
                                        <div className="box" style={{ padding: '3rem 3rem 0.5rem 3rem' }}>
                                            <section className="hero is-dark">
                                                <div className="hero-body">
                                                    <div className="container">
                                                        <h1 className="title is-uppercase">
                                                            Rest
                          </h1>
                                                    </div>
                                                </div>
                                            </section>
                                            <ExerciseTimer length={this.state.restTime} autoStart callBack={this.restTimeDone} />
                                            <section className="section" style={{ marginTop: '16%', marginRight: '-7%' }}>
                                                <div className="column has-text-right">
                                                    <button onClick={() => this.toggleRestModal()} className="button is-medium is-primary skip">{this.state.restTime === 0 ? 'Continue' : 'Skip'}</button>
                                                    {!_.isEmpty(this.context.workout.workout.children[this.state.activeExerciseIndex].children[this.state.activeSubExerciseIndex]) ?
                                                        <p style={{ fontWeight: '700' }}><span className="icon has-text-primary has-font-weight" style={{ marginTop: '10px' }}><i className="fas fa-long-arrow-alt-right"></i></span>Up Next: {this.context.workout.workout.children[this.state.activeExerciseIndex].children[this.state.activeSubExerciseIndex].label}</p>
                                                        : !_.isEmpty(this.context.workout.workout.children[this.state.activeExerciseIndex]) ? <p style={{ fontWeight: '700' }}><span className="icon has-text-primary has-font-weight" style={{ marginTop: '10px' }}><i className="fas fa-long-arrow-alt-right"></i></span>Up Next: {this.context.workout.workout.children[this.state.activeExerciseIndex].label}</p> : null
                                                    }
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div> : ''}
                        </React.Fragment>)}
                </WorkoutContext.Consumer>
        );
    }
}

Exercise.contextType = WorkoutContext;
export default withRouter(Exercise);
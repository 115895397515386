import React, { Component } from 'react';
import '../styles/App.scss';
import moment from 'moment';
import axios from 'axios';
import {
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";

import {
  FacebookIcon,
  TwitterIcon,
} from "react-share";

import Logo from '../assets/logo-new-dark.png';
import LogoLight from '../assets/logo-new-light.png';
import Plankk from '../assets/plankk.png';


import LandingPage from './pages/landing/landingPage';
import ExercisePage from './pages/exercise/exercisePage';
import ShopPage from './pages/shop/shopPage';
import MealPlanPage from './pages/mealPlan/mealPlanPage';
import ProfilePage from './pages/profile/profilePage';
import Admin from './pages/admin/adminPage';
import OnboardPage from './pages/onboard/onboard';
import Privacy from './pages/legal/privacy';
import Tos from './pages/legal/tos';

import SignIn from './auth/signIn';
import Register from './auth/register';
import Account from './pages/account/account';
import ResetPassword from './pages/resetPassword/resetPassword';
import ChangePassword from './pages/resetPassword/changePassword';

import {withRouter, Route, Link } from 'react-router-dom';
import _ from 'lodash';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import UserContext from '../context/user/UserContext';
import WorkoutContext from '../context/workout/WorkoutContext';

import P3 from '../utils/P3';
import StripeContext from '../context/stripe/StripeContext';


class App extends Component {
  
  constructor(props) {
    
    super(props);

    this.apiConnection = React.createRef();

    this.state = {
      isAuth: !_.isEmpty(localStorage.getItem('pcc-isAuthenticated')) ? JSON.parse(localStorage.getItem('pcc-isAuthenticated')) : false,
      user: !_.isEmpty(localStorage.getItem('pcc-user')) ? JSON.parse(localStorage.getItem('pcc-user')) : {},
      login: this.login,
      loginFB: this.loginFB,
      loginGoogle: this.loginGoogle,
      signup: this.signup,
      resetPassword: this.resetPassword,
      changePassword: this.changePassword,
      logout: this.logout,
      goToCheckout: this.goToCheckout,
      cleverTapEvent: this.cleverTapEvent,
      cleverTapProfile: this.cleverTapProfile,
      showingHamburger: false,
      workoutCompleteModalOpen: false,
      selectPlanModalOpen: false,
      totalTimeInterval: '',
      totalTime: 0,
      completedWorkoutStats: {
        name: '',
        totalWeight: 0,
        totalTime: 0
      },
      stripe: null,
      currentWorkout: {
        workout: {},
        convertType: this.convertType,
        weightSummary: 0,
        exerciseTime: 0,
        progress: 0,
        finishExercise: this.finishExercise,
        startWorkout: this.startWorkout,
        finishWorkout: this.finishWorkout,
        startStopWorkoutTotal: this.startStopWorkoutTotal,
        updateExerciseWeight: this.updateExerciseWeight,
        updateExerciseReps: this.updateExerciseReps,
        getNotes: this.getNotes,
        addNote: this.addNote,
      },
      noPlan: false,
      stripeError: '',
      selectedPlan: ''
    };
  }

  login = async (email, password) => {
    let response = await P3.signIn(email, password);
    if(response.success) {
      // Signed in
      response.user.token = response.token;

      await this.setState({
        isAuth: true,
        user: response.user
      });

      await this.fetchUserPlanData();
      await this.cleverTapProfile();
      await this.cleverTapEvent('Log In');

      // Do sub check
      await this.subscriptionCheck(response.user.email);
      localStorage.setItem('pcc-isAuthenticated', true);
      localStorage.setItem('pcc-user', JSON.stringify(response.user));
    }

    return response;
  }

  cleverTapProfile = async (firstName = null, lastName = null, email = null, phone = null) => {
    if(window.clevertap !== undefined) {
      window.clevertap.onUserLogin.push({
        "Site": {
          "Name": !_.isEmpty(firstName) ? firstName + ' ' + !_.isEmpty(lastName) ? lastName : '' : this.state.user.profile.name,
          "Identity": !_.isEmpty(this.state.user._id) ? this.state.user._id : null,
          "Email": !_.isEmpty(email) ? email : this.state.user.email,
          "Phone": !_.isEmpty(phone) ? '+'+phone : '+'+this.state.user.profile.phone
        }
       });
    }
  }

  cleverTapEvent = async (eventName, details = null) => {
    if(window.clevertap !== undefined) {
      if(details === null) {
        window.clevertap.event.push(eventName);
      }
      else {
        window.clevertap.event.push(eventName, details);
      }
    }
  }

  loginFB = async (email, fbId, pictureUrl, phone = null) => {
    let response = await P3.signInFB(email, fbId, pictureUrl, phone);
    if(response.success) {
      response.user.token = response.token;

      await this.setState({
        isAuth: true,
        user: response.user
      });

      await this.fetchUserPlanData();
      await this.cleverTapProfile();
      await this.cleverTapEvent('Facebook Login');

      // Do sub check
      await this.subscriptionCheck(response.user.email);
      localStorage.setItem('pcc-isAuthenticated', true);
      localStorage.setItem('pcc-user', JSON.stringify(response.user));
    }

    return response;
  }

  loginGoogle = async (name, email, gId, pictureUrl, phone = null) => {
    let response = await P3.signInGoogle(name, email, gId, pictureUrl, phone);
    if(response.success) {

      response.user.token = response.token;

      await this.setState({
        isAuth: true,
        user: response.user
      });

      await this.fetchUserPlanData();

      await this.cleverTapProfile();
      await this.cleverTapEvent('Google Login');

      // Do sub check
      this.subscriptionCheck(response.user.email);
      localStorage.setItem('pcc-isAuthenticated', true);
      localStorage.setItem('pcc-user', JSON.stringify(response.user));
    }

    return response;
  }

  signup = async (name, email, password, phone) => {
    let response = await P3.register(name, email, password);
    if(response.success) {
      // Signed in
      response.user.token = response.token;

      await this.setState({
        isAuth: true,
        user: response.user
      });

      let updatedProfile = response.user.profile;
      updatedProfile.phone = phone;

      let updatedUser = await P3.updateUser(response.user.token, {profile: updatedProfile});

      if(updatedUser.success) {
        await this.setState({
          user: {...updatedUser.user, token: response.user.token, _id: response.user._id}
        });
  
      }
      
      await this.fetchUserPlanData();
      await this.cleverTapProfile();

      localStorage.setItem('pcc-isAuthenticated', true);
      localStorage.setItem('pcc-user', JSON.stringify(response.user));
    }

    return response;

  }

  resetPassword = async (email) => {
    let response = await P3.resetPassword(email);
    return response;
  }

  changePassword = async (oldPassword, newPassword) => {
    let response = await P3.changePassword(oldPassword, newPassword, this.state.user.token);
    return response;
  }

  signupWithMenud = (email) => {
    // we need to signup with Menud and then save the authToken to the user Profile.
    axios.post(process.env.REACT_APP_MENUD_SIGNUP_API, {
      username: process.env.NODE_ENV === 'production' ? email : 'brendan@plankk.com',
      email: process.env.NODE_ENV === 'production' ? email : 'brendan@plankk.com'
    }).then (async menudResponse => {
      const userProfile = _.cloneDeep(this.state.user.profile);
      userProfile.stripe_menud_token = menudResponse.data.accessToken.id;

      this.setState({
        user: {
          ...this.state.user,
          profile: userProfile
        }
      });
      await P3.updateUser(this.state.user.token, {profile: userProfile});
    }).catch( error => {
      if (error.response.data.Error.includes('username already exists')) {
        axios.post(process.env.REACT_APP_MENUD_SIGNUP_API, {
          username: process.env.NODE_ENV === 'production' ? email : 'brendan@plankk.com',
          email: process.env.NODE_ENV === 'production' ? email : 'brendan@plankk.com',
          adminOverrideCode: process.env.REACT_APP_MENUD_SIGNUP_ADMIN_CODE,
        }).then (async menudResponse => {
          const userProfile = _.cloneDeep(this.state.user.profile);
          userProfile.stripe_menud_token = menudResponse.data.accessToken.id;

          this.setState({
            user: {
              ...this.state.user,
              profile: userProfile
            }
          });
          await P3.updateUser(this.state.user.token, {profile: userProfile});
        }).catch( error2 => {
          console.log(error2.response.data);
        });
      }
    });
  }

  subscriptionCheck = (email) => {
    if(process.env.NODE_ENV === 'production' && !_.includes(process.env.REACT_APP_AUTH_EMAILS.split(' '), email)) {
      axios.post(process.env.REACT_APP_GATEWAY_ENDPOINT, {email: email})
      .then(response => {
        if(response.data.success) {

          if (!this.state.user.profile.stripe_menud_token) {
            this.signupWithMenud(email);
          }

          if(moment(response.data.subscription.endPlan_date) > moment()) {
            this.setState(prevState => ({
              user: {...prevState.user, subscription: response.data.subscription}
            }));
          }
        }
        else {
          // No subscription found for this user?
          // Handle error here. Logout? Boot to signup/onboarding flow
          this.props.history.push('/');
          this.toggleSelectPlanModal();
        }
      }).catch(error => {
        console.log(error);
      });
    }
    else {
      // In dev. Just allow also make sure we good for menud
      if (!this.state.user.profile.stripe_menud_token) {
        this.signupWithMenud(email);
      }
    }
  }

  updateOnboarding = (questionId, answer) => {

    let onboardingQuestions = this.state.user.onboarding;
    let currentQuestionIndex = onboardingQuestions.findIndex(q => q._id === questionId);
    let currentQuestion = onboardingQuestions.find(q => q._id === questionId);
    currentQuestion.user_answer = answer;
    onboardingQuestions[currentQuestionIndex] = currentQuestion;

    this.setState(prevState => ({
      ...prevState,
      user: {...prevState.user, onboarding: onboardingQuestions}
    }));

    return currentQuestion;
  }

  submitOnboarding = async () => {
    await P3.updateOnboarding(this.state.user.token, this.state.user._id, this.state.user.onboarding);
  }

  logout = () => {
    this.props.history.push('/');
    localStorage.setItem('pcc-isAuthenticated', false);
    localStorage.removeItem('pcc-user');

    this.cleverTapEvent('Log Out');

    this.setState({
      isAuth: false,
      user: {}
    });
  }

  toggleHamburger = () => {
    this.setState({showingHamburger: !this.state.showingHamburger});
  }

  removeNotification = () => {
    this.apiConnection.current.classList.toggle('is-hidden');
  }

  startWorkout = async () => {
    document.getElementsByTagName('html')[0].classList.remove('is-clipped');
    if(this.state.currentWorkout.workout.type === 'rest') {
      // Just proceed for now
      this.finishWorkout();
    }
    else {
      this.props.history.push('/exercise/'+ this.state.currentWorkout.workout._id + '/' + this.state.currentWorkout.workout.workout.children.find(workout => !workout.complete)._id);
    }

    await this.cleverTapEvent('Start Workout', {"Workout Name": this.state.currentWorkout.workout.label, "Workout ID": this.state.currentWorkout.workout._id, "Workout Week": this.state.currentWorkout.workout.week, "Workout Day": this.state.currentWorkout.workout.weekday});
  }

  finishExercise = async (exerciseId) => {
    let exercise = this.state.currentWorkout.workout.workout.children.find(exercise => exercise._id === exerciseId);
    let exerciseIndex = this.state.currentWorkout.workout.workout.children.findIndex(exercise => exercise._id === exerciseId);
    exercise.complete = true;
    let newExercisesList = this.state.currentWorkout.workout.workout.children;
    newExercisesList[exerciseIndex] = exercise;

    await this.setState({ currentWorkout: {...this.state.currentWorkout, workout: {...this.state.currentWorkout.workout, workout: {...this.state.currentWorkout.workout.workout, children: newExercisesList} }}});

    if(!_.isEmpty(this.state.currentWorkout.workout.workout.children[exerciseIndex+1])){
      this.props.history.push('/exercise/' + this.state.currentWorkout.workout._id + '/' + this.state.currentWorkout.workout.workout.children[exerciseIndex+1]._id);
    }
  }

  updateExerciseWeight = async (exerciseId, newWeight) => {
    let response = await P3.updateExerciseWeight(this.state.user.token, exerciseId, newWeight);
    if(response.success) {
      // Exercise Updated
    }
    else {
      // Error updating
    }

    await this.cleverTapEvent('Update Exercise Weight');
  }

  updateExerciseReps = async (exerciseId, newReps) => {
    let response = await P3.updateExerciseReps(this.state.user.token, exerciseId, newReps);
    if(response.success) {
      // Exercise Updated
    }
    else {
      // Error updating
    }

    await this.cleverTapEvent('Update Exercise Reps');
  }

  getNotes = async (exerciseId) => {
    let notes = await P3.getNotes(this.state.user.token, exerciseId);
    return notes;
  }

  addNote = async (exerciseId, message) => {
    let response = await P3.addNote(this.state.user.token, this.state.user._id, exerciseId, message);
    return response;
  }

  finishWorkout = async () => {
    let currentWorkout = this.state.currentWorkout.workout;

    currentWorkout.completed = true;

    if(currentWorkout.type !== 'rest') {
      await this.toggleWorkoutCompleteModal(this.state.currentWorkout);
    }

    await this.updateProgress(currentWorkout);

    await this.fetchUserPlanData();
    await this.cleverTapEvent('Finish Workout', {"Workout Name": currentWorkout.label, "Workout ID": currentWorkout._id, "Workout Week": currentWorkout.week, "Workout Day": currentWorkout.weekday});
    this.props.history.push('/account');
  }

  updateProgress = async (currentWorkout) => {
    let key = 'savedData_'+ this.state.user.plans[0]._id;

    let saveDataObject = this.state.user.profile;
    let progress = [];

    if(_.has(this.state.user.profile, key)) {
      // Progress exists. Just add to it.
      progress = JSON.parse(this.state.user.profile[key]);
      progress.push({
        _id: currentWorkout._id,
        workoutComplete: 'Yes',
        dateWithYear: moment().format('YYYY-MM-DD'),
        weekday: currentWorkout.weekday,
        week: currentWorkout.week,
        date: moment().format('MMM DD'),
        duration: moment.duration('00:'+this.state.completedWorkoutStats.totalTime).seconds(),
        label: currentWorkout.label
      });
    }
    else {
      // No progress yet. Build object
      progress[0] = {
          _id: currentWorkout._id,
          workoutComplete: 'Yes',
          dateWithYear: moment().format('YYYY-MM-DD'),
          weekday: currentWorkout.weekday,
          week: currentWorkout.week,
          date: moment().format('MMM DD'),
          duration: moment.duration('00:'+this.state.completedWorkoutStats.totalTime).seconds(),
          label: currentWorkout.label
      }
    }

    saveDataObject[key] = JSON.stringify(progress);
    await P3.updateUser(this.state.user.token, {profile: saveDataObject});
  }

  startStopWorkoutTotal = async () => {
    if(!_.isInteger(this.state.totalTimeInterval)) {
      // Start timer
      let timer = setInterval(async () => {
        await this.setState({ totalTime: this.state.totalTime + 1 });
      }, 1000);

      await this.setState({ totalTimeInterval: timer });
    }
    else {
      // Stop Timer
      clearInterval(this.state.totalTimeInterval);
      await this.setState({ totalTimeInterval: '' });
    }
  }

  toggleWorkoutCompleteModal = async (completedWorkout = null) => {
    if(this.state.workoutCompleteModalOpen) {
      document.getElementsByTagName('html')[0].classList.remove('is-clipped');
    }
    else {
      document.getElementsByTagName('html')[0].classList.add('is-clipped');
    }

    if(!_.isEmpty(completedWorkout)) {
      let totalWeight = 0;
      
      this.props.history.push('/account');

      let exerciseCount = 0;

      completedWorkout.workout.workout.children.forEach(exercise => {
        let sets = !_.isEmpty(exercise.sets) ? parseInt(exercise.sets) : 1;

        if(exercise.children.length > 0) {
          exerciseCount = exerciseCount + (exercise.children.length * sets);
        }
      })


      await this.setState(prevState => ({
        workoutCompleteModalOpen: !this.state.workoutCompleteModalOpen,
        totalTime: 0,
        currentWorkout: {
          ...prevState.currentWorkout
        },
        completedWorkoutStats: {
          totalWeight: totalWeight.toLocaleString(),
          totalTime: moment.utc(prevState.totalTime*1000).format('m:ss'),
          name: completedWorkout.workout.label,
          exerciseCount: exerciseCount
        }
      }));
    }
    else {
      await this.setState({
        completedWorkoutStats: {
          name: '',
          totalWeight: 0,
          totalTime: 0
        }
      });
      await this.setState({workoutCompleteModalOpen: !this.state.workoutCompleteModalOpen});
    }
  }

  toggleSelectPlanModal = () => {
    if(this.state.selectPlanModalOpen) {
      document.getElementsByTagName('html')[0].classList.remove('is-clipped');
    }
    else {
      document.getElementsByTagName('html')[0].classList.add('is-clipped');
    }

    this.setState(prevState => ({
      selectPlanModalOpen: !prevState.selectPlanModalOpen
    }));

  }

  convertType = (workoutType) => {
    switch(workoutType) {
      case 'group':
        return 'Circuit';
      case 'exercise':
        return 'Exercise';
      default:
        return 'Exercise'
    }
  }

  getYear() {
    return new Date().getFullYear();
  }

  fetchUserPlanData = async () => {
    let customPlanResponse = await P3.getCustomPlans(this.state.user.token);
    let userOnboardingResponse = await P3.getOnboardingInfo(this.state.user.token);

    let onboardingData = [];
    if(userOnboardingResponse.data.success) {
      onboardingData = _.sortBy(userOnboardingResponse.data.questions, q => parseInt(q.index));
    }

    await this.setState(prevState => ({
      user: {...prevState.user, onboarding: onboardingData}
    }));


    if(customPlanResponse.data.success && customPlanResponse.data.customplans.length > 0) {
      // User has custom plan get full details
      let myPlan = await P3.getCustomPlan(this.state.user.token, customPlanResponse.data.customplans[0]._id);
      await this.setState({noPlan: false});
      if(myPlan.data.success) {
        let sortedWorkoutDays = _.sortBy(myPlan.data.plan.workoutdays, [(day) => {
          return parseInt(day.week, 10);
        },(day) => {
          return parseInt(day.weekday, 10);
        }]);
  
        myPlan.data.plan.workoutdays = sortedWorkoutDays;
        
        let key = 'savedData_'+myPlan.data.plan._id;
        let nextWorkout;
        if(_.has(this.state.user.profile, key)) {
          // Progress exists. Lets check it out
          let nextWorkoutId = _.findIndex(myPlan.data.plan.workoutdays, {_id: JSON.parse(this.state.user.profile[key]).slice(-1)[0]._id});
          nextWorkout = myPlan.data.plan.workoutdays[nextWorkoutId+1];
        }
        else {
          nextWorkout = myPlan.data.plan.workoutdays[0];
        }

        await this.setState(prevState => ({
          user: {...prevState.user, plans: [myPlan.data.plan]},
          currentWorkout: {...prevState.currentWorkout, workout: nextWorkout}
        }));
      }
    }
    else {
      await this.setState({noPlan: true});
    }
  }

  goToCheckout = async (selectedPlan, email) => {
    this.state.stripe.redirectToCheckout({
        items: [{ plan: selectedPlan, quantity: 1 }],
        successUrl: process.env.REACT_APP_SITE_URL + '/sign-up/success',
        cancelUrl: process.env.REACT_APP_SITE_URL + '/sign-up/cancel',
        customerEmail: email,
        billingAddressCollection: 'required'
    }).then((result) => {
        if (result.error) {
            this.setState({stripeError: result.error.message});
        }
    });
  }

  selectPlan = async (e) => {
    await this.setState({selectedPlan: e.target.value});
  }

  async componentDidMount() {
      // When app loads check that P3 API is available
      let apiUp = await P3.getStatus();
      if(apiUp.statusText === 'OK') {
        // API is up and ready for us
        if(!_.isEmpty(this.state.user)) {
          // User already logged in. Check token
          let response = await P3.getUser(this.state.user.token);
          if(!_.isEmpty(response) && !_.isEmpty(response.data) && response.data.success) {
            response.data.user.token = this.state.user.token;
            await this.setState({ user: response.data.user });
            await this.fetchUserPlanData();
            await this.subscriptionCheck(this.state.user.email);
            if(_.isEqual(this.props.location.pathname, '/')) {
              this.props.history.push('/account');
            }
          }
          else {
            this.apiConnection.current.classList.toggle('is-hidden');
            this.logout();
          }
        }


      }
      else {
        this.apiConnection.current.classList.toggle('is-hidden');
      }


      // Async stripe setup
      if (window.Stripe) {
        this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_KEY)});
      } else {
        document.querySelector('#stripe-js').addEventListener('load', () => {
          // Create Stripe instance once Stripe.js loads
          this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_KEY)});
        });
      }

      // Lastly check for a netlify query param for redirect
      if(this.props.location.pathname === '/' && (_.includes(this.props.location.hash, '#invite_token=') || _.includes(this.props.location.hash, '#recovery_token='))) {
        // Admin route from netlify. Lets redirect them.
        this.props.history.push('/admin'+this.props.location.hash);
      }

      var scrollpos = window.scrollY;
      var header = document.querySelector("nav");
    
      function add_class_on_scroll() {
        header.classList.add("sticky");
      }
      
      function remove_class_on_scroll() {
        header.classList.remove("sticky");
      }
      
      window.addEventListener('scroll', () => { 
        scrollpos = window.scrollY;
      
        if(scrollpos > 10 || _.includes(this.props.location.pathname, '/exercise') || _.includes(this.props.location.pathname, '/meal-plan')){
            add_class_on_scroll();
        }
        else {
            remove_class_on_scroll();
        }
      });
  }

  render() {
    return (
          <div>
            <UserContext.Provider value={_.omit(this.state, ['totalTime'])}>
              <StripeContext.Provider value={this.state.stripe}>
              <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                      <Link to="/">
                        <div className="navbar-item is-hidden-desktop" style={{cursor: 'pointer'}}>
                        <img src={LogoLight}  alt="logo" className="mobile-logo" />
                        <img src={Logo}  alt="logo" className="mobile-logo-dark" />
                        </div>
                      </Link>
                      <a href="#!" onClick={this.toggleHamburger} role="button" className={`navbar-burger ${this.state.showingHamburger ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                      </a>
                </div>
                <div id="navbarTop" className={`navbar-menu container ${this.state.showingHamburger ? 'is-active ' : ''}`}>
                  <UserContext.Consumer>
                      {( value => (
                        value.isAuth ?
                        <React.Fragment>
                          <div className="navbar-start">
                            <div className="navbar-item">
                              <img src={Logo} alt="logo" className="dark-logo" />
                              <img src={LogoLight}  alt="logo" className="light-logo" />
                            </div>
                          </div>
                          <div className="navbar-end">
                            <Link className="is-uppercase navbar-item" to="/account">My Plan</Link>
                            <Link className="is-uppercase navbar-item" to="/meal-plan">Meal Plan</Link>
                            <Link className="is-uppercase navbar-item" to="/profile">Profile</Link>
                          </div>
                        </React.Fragment> :
                        <React.Fragment>
                          <div className="navbar-start">
                            {this.props.location.pathname === '/' ? <AnchorLink offset='200' className="navbar-item is-uppercase has-font-weight-bold" href="#features">Features</AnchorLink> : <Link className="navbar-item is-uppercase has-font-weight-bold" to="/#features">Features</Link>}
                            {this.props.location.pathname === '/' ? <AnchorLink offset='200' className="navbar-item is-uppercase has-font-weight-bold" href="#join">Pricing</AnchorLink> : <Link className="navbar-item is-uppercase has-font-weight-bold" to="/#cta">Pricing</Link>}
                            {this.props.location.pathname === '/' ? <AnchorLink offset='200' className="navbar-item is-uppercase has-font-weight-bold" href="#pricing">About</AnchorLink> : <Link className="navbar-item is-uppercase has-font-weight-bold" to="/#join">About</Link>}
                          </div>
                          <div className="navbar-center is-hidden-mobile">
                            <img src={Logo} alt="logo" className="dark-logo" />
                            <img src={LogoLight}  alt="logo" className="light-logo" />
                          </div>
                          <div className="navbar-end">
                              <Link className={`navbar-item is-uppercase has-font-weight-bold`} to="/sign-in">Log In</Link>
                              <Link className={`navbar-item is-uppercase has-font-weight-bold`} to="/sign-up"><button className="button is-medium is-primary">Sign Up</button></Link>
                          </div>
                        </React.Fragment>
                      ))}
                    </UserContext.Consumer>
                  </div>
              </nav>
              <div className="main-content has-nav-fixed">
                <div ref={this.apiConnection} id="server-down" className="notification is-warning is-hidden">
                  <button onClick={this.removeNotification} className="delete"></button>
                  <strong>Uh Oh</strong>, Unable to connect to the server. If this warning persists. Please contact <a rel="noopener noreferrer" href="mailto:support@plankk.com" target="_blank">support@plankk.com</a>
                </div>
                <Route path="/" exact component={LandingPage} />
                <Route path="/sign-in" component={SignIn} />
                <Route path="/sign-up/:payResult?" component={Register} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route path="/admin" render={(props) => <Admin {...props} />} />
                <ProtectedRoute path="/onboard" render={(props) => <OnboardPage cleverTapEvent={this.cleverTapEvent} updateOnboarding={this.updateOnboarding} submitOnboarding={this.submitOnboarding} user={this.state.user}/>} />
                <ProtectedRoute path="/profile" component={ProfilePage} />
                <ProtectedRoute path="/meal-plan" component={MealPlanPage} />
                <ProtectedRoute path="/shop" component={ShopPage} />
                <ProtectedRoute path="/change-password" component={ChangePassword} />
                <WorkoutContext.Provider value={_.merge(this.state.currentWorkout, {totalTime: this.state.totalTime})}>
                  <ProtectedRoute path="/account" component={Account} />
                  <ProtectedRoute path="/exercise/:workoutday/:exercise/:subExercise?" component={ExercisePage} />
                  <div id="finish-workout-modal" className={`modal ${this.state.workoutCompleteModalOpen ? 'is-active is-clipped' : ''}`}>
                    <div className="modal-background" onClick={() => this.toggleWorkoutCompleteModal()}></div>
                      <div className="modal-content">
                        <div className="box">
                          <section className="hero is-dark">
                            <div className="hero-body">
                              <div className="container">
                                <h1 className="title is-uppercase">
                                  Congratulations<br />
                                </h1>
                              </div>
                            </div>
                          </section>
                          <div className="container workout-info" style={{minHeight: '50vh'}}>
                            <h2>You crushed {this.state.completedWorkoutStats.name}
                              <span className="social-share">
                                <FacebookShareButton quote={'I just completed a workout on '+process.env.REACT_APP_SITE_NAME} url={process.env.REACT_APP_SITE_URL}>
                                  <FacebookIcon size={32} round={true} />
                                </FacebookShareButton>
                                <TwitterShareButton title={process.env.REACT_APP_SITE_NAME} url={process.env.REACT_APP_SITE_URL}>
                                  <TwitterIcon size={32} round={true} />
                                </TwitterShareButton>
                              </span>
                            </h2>
                            <div className="container">
                              <h6 className="exercise-count"><span>{this.state.completedWorkoutStats.exerciseCount}</span><br /> Exercises</h6>
                              <h6 className="exercise-total"><span>{this.state.completedWorkoutStats.totalTime}</span><br /> Duration</h6>
                            </div>
                            <button onClick={() => this.toggleWorkoutCompleteModal()} className="is-pulled-right button is-medium is-primary">Done</button>
                          </div>
                        </div>
                      </div>
                      <button className="modal-close is-large" onClick={() => this.toggleWorkoutCompleteModal()} aria-label="close"></button>
                  </div>
                </WorkoutContext.Provider>
                <Route path="/privacy" component={Privacy} />
                <Route path="/terms" component={Tos} />
              </div>
              <div id="select-plan-modal" className={`modal ${this.state.selectPlanModalOpen ? 'is-active is-clipped' : ''}`}>
                  <div className="modal-background"></div>
                    <div className="modal-content">
                      <div className="box">
                        <section className="hero is-dark">
                          <div className="hero-body">
                            <div className="container">
                              <h1 className="title is-uppercase">
                                Uh Oh
                              </h1>
                            </div>
                          </div>
                        </section>
                        <div className="container workout-info" style={{minHeight: '50vh'}}>
                          <p>Looks like your account does not have an active subscription to {process.env.REACT_APP_SITE_NAME}</p>
                          <p className={`${this.state.noSelection ? 'has-text-danger' : ''}`}>Select a plan:</p>
                          <div className="control plan-select" onChange={this.selectPlan}>
                              <div className="columns">
                                  <div className={`column box ${this.state.selectedPlan === process.env.REACT_APP_STRIPE_SUB_MONTHLY ? 'has-background-primary has-text-white' : ''}`}>
                                      <label className="radio">
                                          <input type="radio" value={process.env.REACT_APP_STRIPE_SUB_MONTHLY} name="subscription" />
                                          Monthly - ${process.env.REACT_APP_STRIPE_SUB_MONTHLY_PRICE}
                                      </label>
                                  </div>
                              </div>
                              <div className="columns">
                                  <div className={`column box ${this.state.selectedPlan === process.env.REACT_APP_STRIPE_SUB_MONTHLY_EARLY_BIRD ? 'has-background-primary has-text-white' : ''}`}>
                                      <label className="radio">
                                          <input type="radio" value={process.env.REACT_APP_STRIPE_SUB_MONTHLY_EARLY_BIRD} name="subscription" />
                                          Early Bird Monthly - ${process.env.REACT_APP_STRIPE_SUB_MONTHLY_EARLY_BIRD_PRICE} <span className="is-size-6 has-text-primary">(Save $150)</span>
                                      </label>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <button onClick={() => this.goToCheckout(this.state.selectedPlan, this.state.user.email)} disabled={_.isEmpty(this.state.selectedPlan)} className="button is-large is-primary">Checkout</button>
                        <br /><br /><a href="#!" onClick={() => {this.toggleSelectPlanModal(); this.logout();}}>Next time</a>
                      </div>
                    </div>
                </div>
              </StripeContext.Provider>
            </UserContext.Provider>

              <footer className="footer">
                <div className="container">
                  <div className="columns is-centered">
                    <div className="column has-text-centered is-8">
                        <img src={Logo} alt="Footer Logo" width="300"/><br />
                        <div className="footer-nav">
                          <Link to="/privacy" className="footer-a">Privacy Policy</Link>
                          <Link to="/terms" className="footer-a">Terms of Use</Link>
                        </div>
                        
                        <p><sup>&copy;</sup>Copyright {process.env.REACT_APP_SITE_NAME} {this.getYear()}. All rights reserved</p>
                        <div className="columns is-mobile social">
                          <div className="column">
                            <a className="has-text-black" href="https://www.facebook.com/ZBodyFitness/" target="_blank" rel="noopener noreferrer">
                              <span className="icon">
                                <i className="fab fa-facebook-f"></i>
                              </span>
                            </a>
                          </div>
                          <div className="column">
                            <a className="has-text-black" href="https://twitter.com/zoelivelovelift" target="_blank" rel="noopener noreferrer">
                              <span className="icon">
                                <i className="fab fa-twitter"></i>
                              </span>
                            </a>
                          </div>
                          <div className="column">
                            <a className="has-text-black" href="https://www.instagram.com/zoelivelovelift/?hl=en" target="_blank" rel="noopener noreferrer">
                              <span className="icon">
                                <i className="fab fa-instagram"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>

                <div className="container plankk-power">
                  <div className="columns">
                    <div className="column has-text-centered">
                      <p>POWERED BY</p>
                      <a href="https://plankk.com" target="_blank" rel="noopener noreferrer">
                        <img src={Plankk} alt="Plankk Fitness Apps" />
                      </a>
                    </div>
                  </div>
                </div>
            </footer>
          </div>
    );
  }
}

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <UserContext.Consumer>
    { userContext => (
      <Route
        render={props =>
          userContext.isAuth ? <Component {...props} /> :
            <section className="section hero is-small" style={{marginTop: '10vh'}}>
              <div className="hero-body">
                <div className="container">
                  <h1 className="title">
                    Unauthorized
                  </h1>
                  <h2 className="subtitle">
                    401
                  </h2>
                </div>
              </div>
            </section>
        }
        {...rest}
      />
    )}
  </UserContext.Consumer>
);


App.contextType = UserContext;
export default withRouter(App);

import React, { Component } from "react"
import WorkoutContext from '../../../context/workout/WorkoutContext';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import ExerciseTimer from './exerciseTimer';

class SuperSets extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSet: 0,
            exercise_weight: 0,
            exercise_repeat_original: this.setReps(props.exercises[0]),
            exercise_repeat: this.setReps(props.exercises[0]),
            exercisesComplete: []
        }
    }

    componentDidMount() {
        document.addEventListener('keyup', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKeyPress);
    }

    handleKeyPress = (e) => {
        if (e.isTrusted && e.ctrlKey && e.key === 'c' && (this.state.exercisesComplete.length < this.props.exercises.length * this.props.setCount)) {
            // Keyboard for Next set
            this.nextExercise();
        }
    }

    updateWeight = async (e) => {
        await this.setState({ exercise_weight: e.target.value });
    }

    updateReps = async (e) => {
        await this.setState({ exercise_repeat: e.target.value });
    }

    nextExercise = async () => {
        if (!_.isEmpty(this.props.exercises[this.props.index].original_weight) || !_.isEmpty(this.props.exercises[this.props.index].user_weight)) {
            // Weight exists check if something has changed
            if (!_.isEmpty(this.props.exercises[this.props.index].user_weight)) {
                // User has updated weight previously. Lets see if something changed.
                if (parseInt(this.state.exercise_weight) !== parseInt(this.props.exercises[this.props.index].user_weight) && this.state.exercise_weight > 0) {
                    // USer changed weight. Lets fire the Api
                    await this.context.updateExerciseWeight(this.props.exercises[this.props.index]._id, this.state.exercise_weight);
                }
            }
            else {
                // Original weight is set. Check if user has changed it.
                if (parseInt(this.state.exercise_weight) !== parseInt(this.props.exercises[this.props.index].original_weight) && this.state.exercise_weight > 0) {
                    // User changed original weight. Lets fire the Api
                    await this.context.updateExerciseWeight(this.props.exercises[this.props.index]._id, this.state.exercise_weight);
                }
            }
        }

        if (this.state.exercise_repeat > 0 && this.state.exercise_repeat !== this.state.exercise_repeat_original) {
            // Repeat exists and something changed
            await this.context.updateExerciseReps(this.props.exercises[this.props.index]._id, this.state.exercise_repeat);
        }

        await this.setState(prevState => ({
            exercisesComplete: [...prevState.exercisesComplete, this.props.exercises[this.props.index]],
        }));

        await this.props.increaseSubExerciseIndex();

        if (this.state.exercisesComplete.length === this.props.exercises.length || this.state.exercisesComplete.length % this.props.exercises.length === 0) {
            // Set Complete
            if (this.state.activeSet + 1 === this.props.setCount) {
                // Circuit Completed
                await this.context.finishExercise(this.props.match.params.exercise);
            }
            else {
                // Next Set of Circuit
                await this.setState(prevState => ({ activeSet: prevState.activeSet + 1 }));
            }
        }
    }

    setReps = (exercise) => {
        let reps = 0;

        if (!_.isEmpty(exercise.user_repeat)) {
            // User / Trainer has changed reps use this first
            reps = parseInt(exercise.user_repeat);
        }
        else if (!_.isEmpty(exercise.repeat_range) && _.includes(exercise.repeat_range, '-')) {
            // Repeat range set use the lower bounds for now as the reps
            reps = parseInt(exercise.repeat_range.split('-')[0]);
        }
        else if (!_.isEmpty(exercise.repeat) && parseInt(exercise.repeat) > 0) {
            // Normal reps set
            reps = parseInt(exercise.repeat);
        }

        return reps;
    }

    displayEquipment = (equipmentArray) => {
        return _.map(equipmentArray, 'label').join(", ");
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.parentIndex, this.props.parentIndex)) {
            // New circuit
            this.setState({ activeSet: 0, exercisesComplete: [], exercise_weight: 0, exercise_repeat_original: this.setReps(this.props.exercises[this.props.index]), exercise_repeat: this.setReps(this.props.exercises[this.props.index]) });
        }
        else {
            if (!_.isEqual(prevProps.exercises[prevProps.index], this.props.exercises[this.props.index])) {
                this.setState({ exercise_weight: 0, exercise_repeat_original: this.setReps(this.props.exercises[this.props.index]), exercise_repeat: this.setReps(this.props.exercises[this.props.index]) });
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                {(this.state.exercisesComplete.length < this.props.exercises.length * this.props.setCount) ?
                    <React.Fragment>
                        <div className="column">
                            <h5 className="title">{this.props.index >= 0 ? this.context.workout.workout.children[this.props.parentIndex].label : ''}</h5>
                            <h5 className="uppercase" style={{ fontWeight: '700' }}>{this.props.exercises[this.props.index].label}</h5>
                            {this.props.exercises[this.props.index].main_equipments.length > 0 ?
                                <p className="equipment">{this.displayEquipment(this.props.exercises[this.props.index].main_equipments)}</p> : null}
                        </div>
                        <div className="column has-text-centered">
                            {parseInt(this.state.exercise_repeat) === 0 ?
                                parseInt(this.props.exercises[this.props.index].time) + parseInt((this.props.exercises[this.props.index].time_in_minutes * 60)) !== 0 &&
                                <React.Fragment>
                                    <ExerciseTimer autoStart length={parseInt(this.props.exercises[this.props.index].time) + parseInt((this.props.exercises[this.props.index].time_in_minutes * 60))} exerciseId={this.props.exercises[this.props.index]._id} />
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className="updateWeight has-text-centered">
                                        <div className="input-wrap">
                                            <input type="number" style={{ color: this.state.exercise_repeat !== this.state.exercise_repeat_original ? '#3cc5e9' : 'white' }} min="1" max="999" className="input" onChange={this.updateReps} value={this.state.exercise_repeat} />
                                            <h3>{parseInt(this.props.exercises[this.props.index].repeat) === 0 && _.isEmpty(this.props.exercises[this.props.index].repeat_range) ? 'Seconds' : 'Reps'}</h3>
                                            {parseInt(this.props.exercises[this.props.index].per_side) ? <p>Per side</p> : null}
                                        </div>
                                    </div>
                                </React.Fragment>}
                        </div>
                        {!_.isEmpty(this.props.exercises[this.props.index].original_weight) ?
                            <React.Fragment>
                                <div className="column">
                                    <div className="updateWeight has-text-centered">
                                        <div className="input-wrap">
                                            <input type="number" style={{ color: this.state.exercise_repeat !== this.state.exercise_repeat_original ? '#3cc5e9' : 'white' }} className="input" min="1" max="999" onChange={this.updateWeight} value={this.state.exercise_weight > 0 ? this.state.exercise_weight : !_.isEmpty(this.props.exercises[this.props.index].user_weight) ? parseInt(this.props.exercises[this.props.index].user_weight) : parseInt(this.props.exercises[this.props.index].original_weight)} />
                                            <h3>lbs</h3>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment> : null}
                    </React.Fragment>
                    : null}
                <div className="column has-text-right">
                    <div className="circuit-next">
                        {((this.state.exercisesComplete.length + 1 < this.props.exercises.length * this.props.setCount) || !_.isEmpty(this.context.workout.workout.children[this.props.parentIndex + 1])) ?
                            <button className="button is-primary is-large" onClick={this.nextExercise}>Next</button>
                            : <button onClick={this.context.finishWorkout} className="button is-primary is-large">Finish</button>}
                        {(!_.isEmpty(this.context.workout.workout.children[parseInt(this.props.parentIndex)]) && ((this.state.exercisesComplete.length + 1 < this.props.exercises.length * this.props.setCount) || !_.isEmpty(this.context.workout.workout.children[this.props.parentIndex + 1]))) ?
                            <React.Fragment>
                                {(!_.isEmpty(this.context.workout.workout.children[parseInt(this.props.parentIndex)].gap_between_sets) && parseInt(this.context.workout.workout.children[parseInt(this.props.parentIndex)].gap_between_sets) > 0) && Number.isInteger((this.state.exercisesComplete.length + 1) / this.props.exercises.length) ?
                                    <p><span className="icon has-text-primary has-font-weight" style={{ marginTop: '10px' }}><i className="fas fa-long-arrow-alt-right"></i></span>Up Next: Rest</p> : null}
                            </React.Fragment>
                            : null}
                        {!_.isEmpty(this.props.exercises[this.props.index + 1]) && (this.state.exercisesComplete.length < this.props.exercises.length * this.props.setCount) ?
                            <p><span className="icon has-text-primary has-font-weight" style={{ marginTop: '10px' }}><i className="fas fa-long-arrow-alt-right"></i></span>Up Next: {this.props.exercises[this.props.index + 1].label}</p>
                            : (this.state.exercisesComplete.length + 1 === this.props.exercises.length * this.props.setCount) ?
                                !_.isEmpty(this.context.workout.workout.children[this.props.parentIndex + 1]) && (this.state.exercisesComplete.length === this.props.exercises.length * this.props.setCount) ? <p style={{ fontWeight: '700' }}><span className="icon has-text-primary has-font-weight" style={{ marginTop: '10px' }}><i className="fas fa-long-arrow-alt-right"></i></span>Up Next: {this.context.workout.workout.children[this.props.parentIndex + 1].label}</p> : null
                                : null}
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

SuperSets.contextType = WorkoutContext;
export default withRouter(SuperSets);